import apiClient from "./axiosInstance"

export const TAfindBrand = async (data: any) => {
  let query = ""
  if (data.email) {
    query = `?email=${data.email}`
  } else if (data.phone) {
    query = `?phone=${data.phone}`
  } else if (data.brandname) {
    query = `?brand_name=${data.brandname}`
  }

  const response = await apiClient.get(`/admin/brand/get${query}`, {})

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error(response.data.message)
  }
}

export const TAfindBrandName = async (data: any) => {
  const response = await apiClient.get(`/admin/brand/brandname?brand_name=${data}`, {})

  if (response.status === 200) {
    return response.data
  }
}

export const TAfindAllBrands = async (page: number, perPage: number, params: any) => {
  const response = await apiClient.get(
    `/admin/brand/getall?page=${page}&perPage=${perPage}&${params}`,
    {},
  )

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Brands not found")
  }
}

export const TAfindBrandById = async (id: string) => {
  const response = await apiClient.get(`/admin/brand/get/${id}`, {})
  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Find User failed")
  }
}

export const TAupdateBrand = async (id: string, data: any) => {
  const response = await apiClient.put(`/admin/brand/update/${id}`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  })

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Update failed")
  }
}

export const TAupdateBrandNote = async (id: string, notes: string[]) => {
  const response = await apiClient.put(
    `/admin/brand/updatenote/${id}`,
    { notes },
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
  )

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Update failed")
  }
}

export const TAdeleteNote = async (id: string) => {
  const response = await apiClient.delete(`admin/brand/deletenote/${id}`, {
    headers: {
      "Content-Type": "application/json",
    },
  })
  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Delete failed")
  }
}

export const TAaddBalance = async (id: string, balance: number) => {
  const response = await apiClient.put(`/admin/brand/addbalance/${id}/${balance}`, {
    headers: {
      "Content-Type": "application/json",
    },
  })

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Update failed")
  }
}

export const TASpamBrand = async (id: string, is_spam: any) => {
  const response = await apiClient.put(`/admin/brand/setspambrand/${id}/${is_spam}`, {})
  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Find User failed")
  }
}

export const TAupdateBrandLogo = async (id: string, data: any) => {
  const response = await apiClient.put(`/admin/brand/updatebrandlogo/${id}`, data, {
    headers: {
      "Content-Type":
        "multipart/form-data; boundary=<calculated when request is sent></calculated>",
    },
  })
  return response
}

export const TAupdateBrandMeeting = async (id: string, meeting: any) => {
  const response = await apiClient.put(
    `/admin/brand/updatemeeting/${id}`,
    { meeting },
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
  )

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Update failed")
  }
}

export const TAupdateBrandStage = async (id: string, stage: any) => {
  const response = await apiClient.put(
    `/admin/brand/stage/${id}`,
    { stage },
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
  )

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Update failed")
  }
}

export const TAmeetingPlanned = async (id: string, status: any) => {
  const response = await apiClient.post(
    `/admin/brand/meeting-planned/${id}`,
    { status },
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
  )

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Update failed")
  }
}

export const TAinformationMailSent = async (id: string, status: any) => {
  const response = await apiClient.post(
    `/admin/brand/information-mail-sent/${id}`,
    { status },
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
  )

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Update failed")
  }
}

export const TAdirectedToAgencyBasedWork = async (id: string, status: any) => {
  const response = await apiClient.post(
    `/admin/brand/directed-to-agency-based-work/${id}`,
    { status },
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
  )

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Update failed")
  }
}

export const TAdemoCampaignCreated = async (id: string, status: any) => {
  const response = await apiClient.post(
    `/admin/brand/demo-campaign-created/${id}`,
    { status },
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
  )

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Update failed")
  }
}

export const TAbalanceLoaded = async (id: string, status: any) => {
  const response = await apiClient.post(
    `/admin/brand/balance-loaded/${id}`,
    { status },
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
  )

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Update failed")
  }
}

export const TAcampaignManagementContinues = async (id: string, status: any) => {
  const response = await apiClient.post(
    `/admin/brand/campaign-management-continues/${id}`,
    { status },
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
  )

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Update failed")
  }
}

export const TAcampaignCompleted = async (id: string, status: any) => {
  const response = await apiClient.post(
    `/admin/brand/campaign-completed/${id}`,
    { status },
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
  )

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Update failed")
  }
}

export const TApersonalAccount = async (id: string, status: any) => {
  const response = await apiClient.post(
    `/admin/brand/personal-account/${id}`,
    { status },
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
  )

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Update failed")
  }
}

export const TAupdateBalance = async (id: string, balance: any) => {
  const response = await apiClient.put(`/admin/brand/balance/${id}`, balance, {
    headers: {
      "Content-Type": "application/json",
    },
  })

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Update failed")
  }
}
