import React, { useEffect, useState } from "react"
import {
  InstagramData,
  TiktokData,
  ProfileData,
  MoneyData,
  SharedPostData,
  InfoType,
} from "../types/profileData"
import TiktokProfilePicture from "./TiktokProfilePicture"
import InstagramProfilePicture from "./InstagramProfilePicture"
import ReadMore from "./ReadMore"
import "./styles/styles.css"
import {
  TAbanUser,
  TAchangePhone,
  TArecoverAccount,
  TAremoveBan,
  TAremoveVerification,
  TAuserIsSpam,
  TAuserUnvisible,
  TAverifyUser,
} from "../services/userAPI"
import { UpdateInsta } from "./updateInstaModal"
import { UpdateTiktok } from "./updateTiktokModal"

const UserProfile = (data: ProfileData) => {
  const [_id, setId] = useState("")
  const [birthday, setBirthday] = useState("")
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [is_banned, setIsBanned] = useState("")
  const [instagramData, setInstagramData] = useState<InstagramData>({
    last_scrape: null,
    username: "",
    biography: "",
    followers: 0,
    following: 0,
    full_name: "",
    profile_pic: "",
    post_number: 0,
    average_like: 0,
    engagement_rate: 0,
    keywords: [],
    shared_posts: [],
    warnings: [],
    is_spam: false,
    unvisible: false,
  })

  const [tiktokData, setTiktokData] = useState<TiktokData>({
    username: "",
    followers: 0,
    following: 0,
    hearts: 0,
    tiktok_nickname: "",
    tiktok_average_like: "",
    tiktok_engagement_rate: 0,
    profile_pic: "",
    verified: false,
    privateAccount: false,
    keywords: [],
  })

  const [moneyData, setMoneyData] = useState<MoneyData>({
    current: 0,
    exchanges: {},
    paparaAccountNo: "",
  })

  const [sharedPostData, setSharedPostData] = useState<SharedPostData[]>([])
  const [job, setJob] = useState("")
  const [country, setCountry] = useState("")
  const [city, setCity] = useState("")
  const [gender, setGender] = useState("")
  const [isWaitingVerification, setIsWaitingVerification] = useState(false)
  const [deleted, setDeleted] = useState("")
  const [notification, setNotification] = useState("")
  const [editor, setEditor] = useState(false)
  const [hobbies, setHobbies] = useState([""])
  const [verification, setVerification] = useState("")
  const [isUpdateInstaOpen, setIsUpdateInstaOpen] = useState(false)
  const [isUpdateTiktokOpen, setIsUpdateTiktokOpen] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)

  useEffect(() => {
    setId(data?._id ?? "")
    setBirthday(data?.birthday ?? "")
    setName(data?.name ?? "")
    setEmail(data?.email ?? "")
    setPhone(data?.phone ?? "")
    setDeleted(data?.deleted ?? "")
    setHobbies(data?.hobbies ?? [""])
    setVerification(data?.verification ?? "")
    setIsBanned(data?.is_banned ?? "")
    setIsWaitingVerification(data?.isWaitingVerification ?? false)
    setInstagramData(
      data?.instagram ?? {
        last_scrape: null,
        username: "",
        biography: "",
        followers: 0,
        following: 0,
        full_name: "",
        profile_pic: "",
        post_number: 0,
        average_like: 0,
        engagement_rate: 0,
        profile_picture: "",
        keywords: [],
        warnings: [],
        is_spam: false,
        unvisible: false,
      },
    )
    setTiktokData(
      data?.tiktok ?? {
        username: "",
        followers: 0,
        following: 0,
        hearts: 0,
        nickname: "",
        average_like: "",
        engagement_rate: 0,
        profile_picture: "",
        verified: false,
        privateAccount: false,
        keywords: [],
      },
    )
    setMoneyData(
      data?.money ?? {
        current: 0,
        exchange: {},
        paparaAccountNo: "",
      },
    )

    setJob(data?.job ?? "")
    setCountry(data?.country ?? "")
    setCity(data?.city ?? "")
    setGender(data?.gender ?? "")

    const validSharedPosts =
      data?.instagram.shared_posts?.map((post) => {
        if (post.location === undefined) {
          return { ...post, location: { name: "unknown" } } // Or any other default value
        }
        return post
      }) ?? []
    setSharedPostData(validSharedPosts)
  }, [data])

  const personalInfo: InfoType[] = [
    { key: "İsim:", value: name },
    { key: "Doğum Tarihi:", value: birthday },
    { key: "Email:", value: email },
    { key: "Meslek:", value: job },
    { key: "Ülke:", value: country },
    { key: "Şehir:", value: city },
    { key: "Cinsiyet:", value: gender },
    { key: "Papara Hesap No:", value: moneyData.paparaAccountNo },
    { key: "Bakiye:", value: moneyData.current },
    { key: "Onay Durumu:", value: isWaitingVerification },
    { key: "Hobiler:", value: hobbies.join(", ") },
  ]

  const warnings = instagramData.warnings.map((warning) => warning.native_name)
  const instagramInfo: InfoType[] = [
    { key: "Kullanıcı Adı:", value: instagramData.username },
    { key: "Tam İsim:", value: instagramData.full_name },
    { key: "Biografi:", value: instagramData.biography },
    { key: "Takipçi:", value: instagramData.followers },
    { key: "Takip:", value: instagramData.following },
    { key: "Gönderi Sayısı:", value: instagramData.post_number },
    { key: "Ortalama Beğeni:", value: instagramData.average_like },
    { key: "Etkileşim Oranı:", value: instagramData.engagement_rate },
    { key: "Nesneler:", value: instagramData.keywords.join(", ") },
    { key: "Uyarılar:", value: warnings.join(", ") },
    { key: "Spam Durumu:", value: instagramData.is_spam ? "True" : "False" },
    { key: "Görünürlük Durumu:", value: instagramData.unvisible ? "True" : "False" },
  ]

  const tiktokInfo: InfoType[] = [
    { key: "Kullanıcı Adı:", value: tiktokData.username },
    { key: "Takma Ad:", value: tiktokData.tiktok_nickname },
    { key: "Takipçi:", value: tiktokData.followers },
    { key: "Takip:", value: tiktokData.following },
    { key: "Kalp Sayısı:", value: tiktokData.hearts },
    { key: "Ortalama Beğeni:", value: tiktokData.tiktok_average_like },
    { key: "Etkileim Oranı:", value: tiktokData.tiktok_engagement_rate },
    { key: "Onar Durumu:", value: tiktokData.verified },
    { key: "Gizli Hesap Durumu:", value: tiktokData.privateAccount },
    { key: "Nesneler:", value: tiktokData.keywords.join(" ") },
  ]

  const recoverAccount = (id: any, status: any) => {
    TArecoverAccount(id, status)
    setNotification("Account Recovered (refresh page)")
  }

  const changePhone = (id: any, phone: any) => {
    TAchangePhone(id, phone)
    setNotification("Phone Number Changed (refresh page)")
  }

  const removeVerification = async (id: any) => {
    const response = await TAremoveVerification(id)
    if (response) {
      setVerification("false")
    }
  }

  const changeSpam = (id: any, status: any) => {
    TAuserIsSpam(id, status)
    setNotification("Spam status changed (refresh page)")
  }

  const changeUnvisible = (id: any, status: any) => {
    TAuserUnvisible(id, status)
    setNotification("Unvisible status changed (refresh page)")
  }

  useEffect(() => {
    if (notification) {
      const notificationTimeout = setTimeout(() => {
        setNotification("")
      }, 5000)

      return () => clearTimeout(notificationTimeout)
    }
  }, [notification])

  const handleChangePhone = (e: any) => {
    const value = e.target.value.replace(/[^0-9]/g, "")
    setPhone(`+${value}`)
  }

  const renderKeywords = (keywords: any) => {
    const toggleExpandedRow = () => {
      setIsExpanded(!isExpanded)
    }
    if (isExpanded) {
      return (
        <div>
          {keywords}
          <button
            type="button"
            onClick={toggleExpandedRow}
            className="text-blue-500 hover:underline"
          >
            Less
          </button>
        </div>
      )
    } else if (keywords.length > 100) {
      return (
        <div>
          {`${keywords.slice(0, 100)}... `}
          <button
            type="button"
            onClick={toggleExpandedRow}
            className="text-blue-500 hover:underline"
          >
            More
          </button>
        </div>
      )
    } else {
      return <div>{keywords}</div>
    }
  }

  const addVerification = async (id: any) => {
    try {
      const response = await TAverifyUser(id, "true")
      if (response) {
        setVerification("true")
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const banUser = async (id: any) => {
    try {
      const response = await TAbanUser(id)
      if (response) {
        setIsBanned("true")
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const removeBan = async (id: any) => {
    try {
      const response = await TAremoveBan(id)
      if (response) {
        setIsBanned("false")
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  return (
    <>
      <div className="profile-container p-14 rounded-lg  w-2/3">
        <div className="flex items-center mb-20">
          <div className="flex flex-row items-center mr-16">
            <div>
              <InstagramProfilePicture instagramData={instagramData} />
              <button
                type="button"
                onClick={() => setIsUpdateInstaOpen(true)}
                className="btn btn-primary mt-5"
              >
                Update Instagram
              </button>

              {isUpdateInstaOpen && (
                <UpdateInsta
                  username={instagramData.username}
                  _id={_id}
                  onClose={() => setIsUpdateInstaOpen(false)}
                />
              )}
            </div>
            <div>
              <TiktokProfilePicture tiktokData={tiktokData} />
              <button
                type="button"
                onClick={() => setIsUpdateTiktokOpen(true)}
                className="btn btn-primary mt-5 ml-7"
              >
                Update TikTok
              </button>

              {isUpdateTiktokOpen && (
                <UpdateTiktok
                  username={tiktokData.username}
                  _id={_id}
                  onClose={() => setIsUpdateTiktokOpen(false)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="profile-section bg-white p-3 shadow-md mb-3">
        {notification && (
          <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-3">
            <span className="flex items-center block sm:inline">{notification}</span>
          </div>
        )}
        <h3 className="section-title text-lg font-semibold mb-3">Personal Information</h3>
        <table className="table-responsive">
          <tbody>
            {personalInfo.map((info: InfoType) => (
              <tr key={info.key}>
                <td>{info.key}</td>
                <td>{info.value}</td>
              </tr>
            ))}
          </tbody>
          <tbody>
            <tr>
              <td>Verification:</td>
              <td>{verification}</td>
              {verification === "true" ? (
                <button
                  className="text-indigo-600 hover:text-indigo-900 pt-3"
                  onClick={() => removeVerification(_id)}
                >
                  Remove Verification
                </button>
              ) : (
                <button
                  className="text-indigo-600 hover:text-indigo-900 pt-3"
                  onClick={() => addVerification(_id)}
                >
                  Add Verification
                </button>
              )}
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td>Phone:</td>
              <td>{phone}</td>
              <button
                className="text-indigo-600 hover:text-indigo-900 pt-1 pb-1"
                onClick={() => setEditor(!editor)}
              >
                Edit Phone Number
              </button>
              {editor && (
                <div className="flex flex-col space-y-2 pb-2">
                  <div className="flex flex-row space-x-1">
                    <input
                      id="phone"
                      type="tel"
                      placeholder="(ex: 905555555555)"
                      value={phone}
                      className="form-input text-sm border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 w-60 pt-1 pb-1"
                      onChange={handleChangePhone}
                    />
                    <button
                      className="text-sm font-medium text-indigo-600 hover:text-indigo-900"
                      onClick={() => changePhone(_id, phone)}
                    >
                      Change
                    </button>
                  </div>
                </div>
              )}
            </tr>
            <tr>
              <td>Deleted:</td>
              <td>{deleted}</td>
              {deleted === "true" && (
                <button
                  className="text-indigo-600 hover:text-indigo-900 pt-3"
                  onClick={() => recoverAccount(_id, false)}
                >
                  Recover Account
                </button>
              )}
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td>Is Banned:</td>
              <td>{is_banned}</td>
              {is_banned === "true" ? (
                <button
                  className="text-indigo-600 hover:text-indigo-900 pt-3"
                  onClick={() => removeBan(_id)}
                >
                  Remove Ban
                </button>
              ) : (
                <button
                  className="text-indigo-600 hover:text-indigo-900 pt-3"
                  onClick={() => banUser(_id)}
                >
                  Ban User
                </button>
              )}
            </tr>
          </tbody>
        </table>
      </div>
      <div className="profile-section bg-white p-3  shadow-md mb-3">
        <h3 className="section-title text-lg font-semibold mb-3">Instagram Data</h3>
        <table className="table-responsive">
          <tbody>
            {instagramInfo.map((info: InfoType) => (
              <tr key={info.key}>
                {info.key === "Is Spam:" ? (
                  <>
                    <td className="py-2 px-4 font-semibold">Is Spam:</td>
                    <td className="py-2 px-4">{info.value}</td>
                    <td className="py-2 px-4">
                      <button
                        className="bg-indigo-600 text-white font-medium py-1 px-3 rounded hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50"
                        onClick={() => changeSpam(_id, !instagramData.is_spam)}
                      >
                        Change spam
                      </button>
                    </td>
                  </>
                ) : info.key === "Unvisible:" ? (
                  <>
                    <td className="py-2 px-4 font-semibold">Unvisible:</td>
                    <td className="py-2 px-4">{info.value}</td>
                    <td className="py-2 px-4">
                      <button
                        className="bg-indigo-600 text-white font-medium py-1 px-3 rounded hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50"
                        onClick={() => changeUnvisible(_id, !instagramData.unvisible)}
                      >
                        Change Unvisiblity
                      </button>
                    </td>
                  </>
                ) : info.key === "Keywords:" ? (
                  <>
                    <td className="py-2 px-4 font-semibold">Keywords:</td>
                    <td className="py-2 px-4">{renderKeywords(info.value)}</td>
                  </>
                ) : (
                  <>
                    <td>{info.key}</td>
                    <td>{info.value}</td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="profile-section bg-white p-3 shadow-md mb-3">
        <h3 className="section-title text-lg font-semibold mb-3">Tiktok Data</h3>
        <table className="table-responsive">
          <tbody>
            {tiktokInfo.map((info: InfoType) => (
              <tr key={info.key}>
                <td>{info.key}</td>
                <td>{info.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 ">
        {sharedPostData.map((post) => (
          <div
            key={post._id}
            className="flex flex-col bg-white rounded-lg shadow-md overflow-hidden max-w-xs mx-auto"
          >
            <div className="flex-shrink-0">
              <img
                className="h-48 w-full object-cover zoom"
                src={post.media_url}
                alt={post.description}
              />
            </div>
            <div className="px-6 py-4">
              <div className="font-bold text-xl mb-2">{post.location.name}</div>
              {post.description && post.description.length > 150 ? (
                <ReadMore content={post.description} maxCharacterCount={150} />
              ) : (
                <p className="text-gray-700 text-base">{post.description}</p>
              )}
            </div>
            <div className="px-6 pt-4 pb-2">
              <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                Likes: {post.like_count}
              </span>
              <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                Comments: {post.comment_count}
              </span>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default UserProfile
