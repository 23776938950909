import React, { useState } from "react"

interface VideoPlayerProps {
  selectedVideo: string
  onClose: () => void
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ selectedVideo, onClose }) => {
  const [useIframe, setUseIframe] = useState(false)

  const handleVideoError = () => {
    setUseIframe(true)
  }

  const handleVideoLoad = () => {
    setUseIframe(false)
  }

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white rounded-lg p-4 w-11/12 md:w-3/4 lg:w-1/2">
        {useIframe ? (
          <iframe
            src={selectedVideo}
            title="iframe-video"
            allow="autoplay"
            allowFullScreen
            className="w-full h-[400px] sm:h-[500px] lg:h-[600px] rounded-lg"
          />
        ) : (
          <video
            src={selectedVideo}
            controls
            autoPlay
            muted
            onCanPlay={handleVideoLoad}
            onError={handleVideoError}
            className="w-full h-[400px] sm:h-[500px] lg:h-[600px] rounded-lg"
          />
        )}
        <button onClick={onClose} className="mt-4 px-4 py-2 bg-red-500 text-white rounded-md">
          Kapat
        </button>
      </div>
    </div>
  )
}

export default VideoPlayer
