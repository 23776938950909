// HOBBIES
interface HobbyItem {
  name: string
  native_name: string
}

interface HobbyGroup {
  items: HobbyItem[]
  group_name: string
  native_name: string
}
const hobbiesList: HobbyGroup[] = [
  {
    items: [
      { name: "basketball", native_name: "Basketbol 🏀" },
      { name: "football", native_name: "Futbol ⚽" },
      { name: "tennis", native_name: "Tenis 🎾" },
      { name: "swim", native_name: "Yüzme 🏊" },
      { name: "cycling", native_name: "Bisiklet 🚴" },
      { name: "boxing", native_name: "Boks 🥊" },
      { name: "handball", native_name: "Hentbol 🤾" },
      { name: "golf", native_name: "Golf ⛳" },
      { name: "ski", native_name: "Kayak ⛷️" },
      { name: "fitness", native_name: "Fitness ve sağlık 💪" },
      { name: "chess", native_name: "Satranç ♟️" },
    ],
    group_name: "sport",
    native_name: "Spor",
  },
  {
    items: [
      { name: "photography", native_name: "Fotoğrafçılık 📷" },
      { name: "painting", native_name: "Ressamlık 🎨" },
      { name: "music", native_name: "Müzik 🎵" },
      { name: "cinema", native_name: "Sinema 🎬" },
      { name: "literature", native_name: "Edebiyat 📚" },
      { name: "theater", native_name: "Tiyatro 🎭" },
    ],
    group_name: "art",
    native_name: "Sanat",
  },
  {
    items: [
      { name: "parent", native_name: "Ebeveynlik 👨‍👩‍👧‍👦" },
      { name: "travel", native_name: "Seyahat 🌍" },
      { name: "fashion", native_name: "Moda 👗" },
      { name: "healthfitness", native_name: "Sağlık ve Form 🧘‍♂️" },
      { name: "luxury", native_name: "Lüks 💎" },
      { name: "adventure", native_name: "Macera 🌄" },
      { name: "cosmetics", native_name: "Güzellik ve Kozmetik 💄" },
      { name: "business", native_name: "İş/Business 💼" },
      { name: "fashiondesign", native_name: "Moda Tasarımı 👠" },
      { name: "books", native_name: "Kitaplar 📚" },
      { name: "shopping", native_name: "Alışveriş ve Moda 🛍️" },
      { name: "mom", native_name: "Anne ve Çocuk 👩‍👧‍👦" },
      { name: "gastronomy", native_name: "Gastronomi / Yemek 🍽️" },
      { name: "animal", native_name: "Evcil Hayvan Sahiplenme 🐾" },
    ],
    group_name: "lifestyle",
    native_name: "Yaşam Tarzı",
  },
  {
    items: [
      { name: "computer", native_name: "Donanım 💻" },
      { name: "cars", native_name: "Otomobil 🚗" },
      { name: "development", native_name: "Yazılım 💾" },
      { name: "games", native_name: "Bilgisayar Oyunları 🎮" },
      { name: "bitcoin", native_name: "Kripto Para 💰" },
    ],
    group_name: "technology",
    native_name: "Teknoloji",
  },
]

const hobbieListEN = [
  {
    items: [
      { name: "basketball", native_name: "Basketball 🏀" },
      { name: "football", native_name: "Football ⚽" },
      { name: "tennis", native_name: "Tennis 🎾" },
      { name: "swim", native_name: "Swimming 🏊" },
      { name: "cycling", native_name: "Cycling 🚴" },
      { name: "boxing", native_name: "Boxing 🥊" },
      { name: "handball", native_name: "Handball 🤾" },
      { name: "golf", native_name: "Golf ⛳" },
      { name: "ski", native_name: "Ski ⛷️" },
      { name: "fitness", native_name: "Fitness and Health 💪" },
      { name: "chess", native_name: "Chess ♟️" },
    ],
    group_name: "sport",
    native_name: "Sports",
  },
  {
    items: [
      { name: "photography", native_name: "Photography 📷" },
      { name: "painting", native_name: "Painting 🎨" },
      { name: "music", native_name: "Music 🎵" },
      { name: "cinema", native_name: "Cinema 🎬" },
      { name: "literature", native_name: "Literature 📚" },
      { name: "theater", native_name: "Theater 🎭" },
    ],
    group_name: "art",
    native_name: "Art",
  },
  {
    items: [
      { name: "parent", native_name: "Parenting 👨‍👩‍👧‍👦" },
      { name: "travel", native_name: "Travel 🌍" },
      { name: "fashion", native_name: "Fashion 👗" },
      { name: "healthfitness", native_name: "Health and Fitness 🧘‍♂️" },
      { name: "luxury", native_name: "Luxury 💎" },
      { name: "adventure", native_name: "Adventure 🌄" },
      { name: "cosmetics", native_name: "Beauty / Cosmetics 💄" },
      { name: "business", native_name: "Business 💼" },
      { name: "fashiondesign", native_name: "Fashion Design 👠" },
      { name: "books", native_name: "Books 📚" },
      { name: "shopping", native_name: "Shopping and Fashion 🛍️" },
      { name: "mom", native_name: "Mother-Child 👩‍👧‍👦" },
      { name: "gastronomy", native_name: "Gastronomy / Food 🍽️" },
      { name: "animal", native_name: "Pet Adoption 🐾" },
    ],
    group_name: "lifestyle",
    native_name: "Lifestyle",
  },
  {
    items: [
      { name: "computer", native_name: "Computer 💻" },
      { name: "cars", native_name: "Car 🚗" },
      { name: "development", native_name: "Software 💾" },
      { name: "games", native_name: "Computer Games 🎮" },
      { name: "bitcoin", native_name: "Cryptocurrency 💰" },
    ],
    group_name: "technology",
    native_name: "Technology",
  },
]

const hobbieListDE = [
  {
    items: [
      { name: "basketball", native_name: "Basketball 🏀" },
      { name: "football", native_name: "Fußball ⚽" },
      { name: "tennis", native_name: "Tennis 🎾" },
      { name: "swim", native_name: "Schwimmen 🏊" },
      { name: "cycling", native_name: "Radsport 🚴" },
      { name: "boxing", native_name: "Boxen 🥊" },
      { name: "handball", native_name: "Handball 🤾" },
      { name: "golf", native_name: "Golf ⛳" },
      { name: "ski", native_name: "Skifahren ⛷️" },
      { name: "fitness", native_name: "Fitness und Gesundheit 💪" },
      { name: "chess", native_name: "Schach ♟️" },
    ],
    group_name: "sport",
    native_name: "Sport",
  },
  {
    items: [
      { name: "photography", native_name: "Fotografie 📷" },
      { name: "painting", native_name: "Malerei 🎨" },
      { name: "music", native_name: "Musik 🎵" },
      { name: "cinema", native_name: "Kino 🎬" },
      { name: "literature", native_name: "Literatur 📚" },
      { name: "theater", native_name: "Theater 🎭" },
    ],
    group_name: "Art",
    native_name: "Kunst",
  },
  {
    items: [
      { name: "parent", native_name: "Elternschaft 👨‍👩‍👧‍👦" },
      { name: "travel", native_name: "Reisen 🌍" },
      { name: "fashion", native_name: "Mode 👗" },
      { name: " healthfitness", native_name: "Gesundheit und Fitness 🧘‍♂️" },
      { name: "luxury", native_name: "Luxus 💎" },
      { name: "adventure", native_name: "Abenteuer 🌄" },
      { name: "cosmetics", native_name: "Schönheit / Kosmetik 💄" },
      { name: "business", native_name: "Geschäftswelt 💼" },
      { name: "fashiondesign", native_name: "Modegestaltung 👠" },
      { name: "books", native_name: "Bücher 📚" },
      { name: "shopping", native_name: "Einkaufen und Mode 🛍️" },
      { name: "mom", native_name: "Mutter-Kind 👩‍👧‍👦" },
      { name: "gastronomy", native_name: "Gastronomie / Essen 🍽️" },
      { name: "animal", native_name: "Haustieradoption 🐾" },
    ],
    group_name: "lifestyle",
    native_name: "Lebensstil",
  },
  {
    items: [
      { name: "computer", native_name: "Computer 💻" },
      { name: "cars", native_name: "Auto 🚗" },
      { name: "development", native_name: "Software 💾" },
      { name: "games", native_name: "Computerspiele 🎮" },
      { name: "bitcoin", native_name: "Kryptowährung 💰" },
    ],
    group_name: "technology",
    native_name: "Technologie",
  },
]

const hobbieListAR = [
  {
    items: [
      { name: "basketball", native_name: "كرة السلة 🏀" },
      { name: "football", native_name: "كرة القدم ⚽" },
      { name: "tennis", native_name: "التنس 🎾" },
      { name: "swim", native_name: "السباحة 🏊" },
      { name: "cycling", native_name: "ركوب الدراجات 🚴" },
      { name: "boxing", native_name: "الملاكمة 🥊" },
      { name: "handball", native_name: "كرة اليد 🤾" },
      { name: "golf", native_name: "الجولف ⛳" },
      { name: "ski", native_name: "التزلج ⛷️" },
      { name: "fitness", native_name: "اللياقة والصحة 💪" },
      { name: "chess", native_name: "الشطرنج ♟️" },
    ],
    group_name: "sport",
    native_name: "رياضة",
  },
  {
    items: [
      { name: "photography", native_name: "التصوير الفوتوغرافي 📷" },
      { name: "painting", native_name: "الرسم 🎨" },
      { name: "music", native_name: "الموسيقى 🎵" },
      { name: "cinema", native_name: "السينما 🎬" },
      { name: "literature", native_name: "الأدب 📚" },
      { name: "theater", native_name: "المسرح 🎭" },
    ],
    group_name: "art",
    native_name: "فن",
  },
  {
    items: [
      { name: "parent", native_name: "الأبوة والأمومة 👨‍👩‍👧‍👦" },
      { name: "travel", native_name: "السفر 🌍" },
      { name: "fashion", native_name: "الأزياء 👗" },
      { name: "healthfitness", native_name: "الصحة واللياقة 🧘‍♂️" },
      { name: "luxury", native_name: "الرفاهية 💎" },
      { name: "adventure", native_name: "المغامرة 🌄" },
      { name: "cosmetics", native_name: "الجمال / مستحضرات التجميل 💄" },
      { name: "business", native_name: "الأعمال 💼" },
      { name: "fashiondesign", native_name: "تصميم الأزياء 👠" },
      { name: "books", native_name: "الكتب 📚" },
      { name: "shopping", native_name: "التسوق والأزياء 🛍️" },
      { name: "mom", native_name: "الأم والطفل 👩‍👧‍👦" },
      { name: "gastronomy", native_name: "الطهي / الطعام 🍽️" },
      { name: "animal", native_name: "تبني الحيوانات الأليفة 🐾" },
    ],
    group_name: "lifestyle",
    native_name: "نمط الحياة",
  },
  {
    items: [
      { name: "computer", native_name: "الكمبيوتر 💻" },
      { name: "cars", native_name: "السيارات 🚗" },
      { name: "development", native_name: "البرمجيات 💾" },
      { name: "games", native_name: "ألعاب الكمبيوتر 🎮" },
      { name: "bitcoin", native_name: "العملات الرقمية 💰" },
    ],
    group_name: "technology",
    native_name: "تكنولوجيا",
  },
]

const hobbieListES = [
  {
    items: [
      { name: "basketball", native_name: "Baloncesto 🏀" },
      { name: "football", native_name: "Fútbol ⚽" },
      { name: "tennis", native_name: "Tenis 🎾" },
      { name: "swim", native_name: "Natación 🏊" },
      { name: "cycling", native_name: "Ciclismo 🚴" },
      { name: "boxing", native_name: "Boxeo 🥊" },
      { name: "handball", native_name: "Balonmano 🤾" },
      { name: "golf", native_name: "Golf ⛳" },
      { name: "ski", native_name: "Esquí ⛷️" },
      { name: "fitness", native_name: "Fitness y Salud 💪" },
      { name: "chess", native_name: "Ajedrez ♟️" },
    ],
    group_name: "sport",
    native_name: "Deporte",
  },
  {
    items: [
      { name: "photography", native_name: "Fotografía 📷" },
      { name: "painting", native_name: "Pintura 🎨" },
      { name: "music", native_name: "Música 🎵" },
      { name: "cinema", native_name: "Cine 🎬" },
      { name: "literature", native_name: "Literatura 📚" },
      { name: "theater", native_name: "Teatro 🎭" },
    ],
    group_name: "art",
    native_name: "Arte",
  },
  {
    items: [
      { name: "parent", native_name: "Paternidad 👨‍👩‍👧‍👦" },
      { name: "travel", native_name: "Viajar 🌍" },
      { name: "fashion", native_name: "Moda 👗" },
      { name: "healthfitness", native_name: "Salud y Fitness 🧘‍♂️" },
      { name: "luxury", native_name: "Lujo 💎" },
      { name: "adventure", native_name: "Aventura 🌄" },
      { name: "cosmetics", native_name: "Belleza / Cosméticos 💄" },
      { name: "business", native_name: "Negocios 💼" },
      { name: "fashiondesign", native_name: "Diseño de Moda 👠" },
      { name: "books", native_name: "Libros 📚" },
      { name: "shopping", native_name: "Compras y Moda 🛍️" },
      { name: "mom", native_name: "Madre e Hijo 👩‍👧‍👦" },
      { name: "gastronomy", native_name: "Gastronomía / Comida 🍽️" },
      { name: "animal", native_name: "Adopción de Mascotas 🐾" },
    ],
    group_name: "lifestyle",
    native_name: "Estilo de vida",
  },
  {
    items: [
      { name: "computer", native_name: "Computadora 💻" },
      { name: "cars", native_name: "Coches 🚗" },
      { name: "development", native_name: "Software 💾" },
      { name: "games", native_name: "Videojuegos 🎮" },
      { name: "bitcoin", native_name: "Criptomoneda 💰" },
    ],
    group_name: "technology",
    native_name: "Tecnología",
  },
]

const hobbieListFR = [
  {
    items: [
      { name: "basketball", native_name: "Basket-ball 🏀" },
      { name: "football", native_name: "Football ⚽" },
      { name: "tennis", native_name: "Tennis 🎾" },
      { name: "swim", native_name: "Natation 🏊" },
      { name: "cycling", native_name: "Cyclisme 🚴" },
      { name: "boxing", native_name: "Boxe 🥊" },
      { name: "handball", native_name: "Handball 🤾" },
      { name: "golf", native_name: "Golf ⛳" },
      { name: "ski", native_name: "Ski ⛷️" },
      { name: "fitness", native_name: "Fitness et Santé 💪" },
      { name: "chess", native_name: "Échecs ♟️" },
    ],
    group_name: "sport",
    native_name: "Sport",
  },
  {
    items: [
      { name: "photography", native_name: "Photographie 📷" },
      { name: "painting", native_name: "Peinture 🎨" },
      { name: "music", native_name: "Musique 🎵" },
      { name: "cinema", native_name: "Cinéma 🎬" },
      { name: "literature", native_name: "Littérature 📚" },
      { name: "theater", native_name: "Théâtre 🎭" },
    ],
    group_name: "art",
    native_name: "Art",
  },
  {
    items: [
      { name: "parent", native_name: "Parent 👨‍👩‍👧‍👦" },
      { name: "travel", native_name: "Voyage 🌍" },
      { name: "fashion", native_name: "Mode 👗" },
      { name: "healthfitness", native_name: "Santé et Forme 🧘‍♂️" },
      { name: "luxury", native_name: "Luxe 💎" },
      { name: "adventure", native_name: "Aventure 🌄" },
      { name: "cosmetics", native_name: "Beauté / Cosmétique 💄" },
      { name: "business", native_name: "Affaires 💼" },
      { name: "fashiondesign", native_name: "Design de Mode 👠" },
      { name: "books", native_name: "Livres 📚" },
      { name: "shopping", native_name: "Shopping et Mode 🛍️" },
      { name: "mom", native_name: "Mère-Enfant 👩‍👧‍👦" },
      { name: "gastronomy", native_name: "Gastronomie / Alimentation 🍽️" },
      { name: "animal", native_name: "Adoption d’Animaux 🐾" },
    ],
    group_name: "lifestyle",
    native_name: "Style de vie",
  },
  {
    items: [
      { name: "computer", native_name: "Ordinateur 💻" },
      { name: "cars", native_name: "Voitures 🚗" },
      { name: "development", native_name: "Logiciel 💾" },
      { name: "games", native_name: "Jeux vidéo 🎮" },
      { name: "bitcoin", native_name: "Cryptomonnaie 💰" },
    ],
    group_name: "technology",
    native_name: "Technologie",
  },
]

const hobbieListHI = [
  {
    items: [
      { name: "basketball", native_name: "बास्केटबॉल 🏀" },
      { name: "football", native_name: "फुटबॉल ⚽" },
      { name: "tennis", native_name: "टेनिस 🎾" },
      { name: "swim", native_name: "तैराकी 🏊" },
      { name: "cycling", native_name: "साइकिल 🚴" },
      { name: "boxing", native_name: "मुक्केबाजी 🥊" },
      { name: "handball", native_name: "हैंडबॉल 🤾" },
      { name: "golf", native_name: "गोल्फ ⛳" },
      { name: "ski", native_name: "स्कीइंग ⛷️" },
      { name: "fitness", native_name: "फिटनेस और स्वास्थ्य 💪" },
      { name: "chess", native_name: "शतरंज ♟️" },
    ],
    group_name: "sport",
    native_name: "खेल",
  },
  {
    items: [
      { name: "photography", native_name: "फोटोग्राफी 📷" },
      { name: "painting", native_name: "पेंटिंग 🎨" },
      { name: "music", native_name: "संगीत 🎵" },
      { name: "cinema", native_name: "सिनेमा 🎬" },
      { name: "literature", native_name: "साहित्य 📚" },
      { name: "theater", native_name: "थिएटर 🎭" },
    ],
    group_name: "art",
    native_name: "कला",
  },
  {
    items: [
      { name: "parent", native_name: "पालन-पोषण 👨‍👩‍👧‍👦" },
      { name: "travel", native_name: "यात्रा 🌍" },
      { name: "fashion", native_name: "फैशन 👗" },
      { name: "healthfitness", native_name: "स्वास्थ्य और फिटनेस 🧘‍♂️" },
      { name: "luxury", native_name: "विलासिता 💎" },
      { name: "adventure", native_name: "साहसिक 🌄" },
      { name: "cosmetics", native_name: "सौंदर्य / प्रसाधन सामग्री 💄" },
      { name: "business", native_name: "व्यापार 💼" },
      { name: "fashiondesign", native_name: "फैशन डिजाइन 👠" },
      { name: "books", native_name: "किताबें 📚" },
      { name: "shopping", native_name: "खरीदारी और फैशन 🛍️" },
      { name: "mom", native_name: "माँ और बच्चा 👩‍👧‍👦" },
      { name: "gastronomy", native_name: "भोजन 🍽️" },
      { name: "animal", native_name: "पालतू जानवर गोद लेना 🐾" },
    ],
    group_name: "lifestyle",
    native_name: "जीवन शैली",
  },
  {
    items: [
      { name: "computer", native_name: "कंप्यूटर 💻" },
      { name: "cars", native_name: "कार 🚗" },
      { name: "development", native_name: "सॉफ्टवेयर 💾" },
      { name: "games", native_name: "कंप्यूटर गेम 🎮" },
      { name: "bitcoin", native_name: "क्रिप्टोकरेन्सी 💰" },
    ],
    group_name: "technology",
    native_name: "प्रौद्योगिकी",
  },
]

const hobbieListIT = [
  {
    items: [
      { name: "basketball", native_name: "Pallacanestro 🏀" },
      { name: "football", native_name: "Calcio ⚽" },
      { name: "tennis", native_name: "Tennis 🎾" },
      { name: "swim", native_name: "Nuoto 🏊" },
      { name: "cycling", native_name: "Ciclismo 🚴" },
      { name: "boxing", native_name: "Pugilato 🥊" },
      { name: "handball", native_name: "Pallamano 🤾" },
      { name: "golf", native_name: "Golf ⛳" },
      { name: "ski", native_name: "Sci ⛷️" },
      { name: "fitness", native_name: "Fitness e Salute 💪" },
      { name: "chess", native_name: "Scacchi ♟️" },
    ],
    group_name: "sport",
    native_name: "Sport",
  },
  {
    items: [
      { name: "photography", native_name: "Fotografia 📷" },
      { name: "painting", native_name: "Pittura 🎨" },
      { name: "music", native_name: "Musica 🎵" },
      { name: "cinema", native_name: "Cinema 🎬" },
      { name: "literature", native_name: "Letteratura 📚" },
      { name: "theater", native_name: "Teatro 🎭" },
    ],
    group_name: "art",
    native_name: "Arte",
  },
  {
    items: [
      { name: "parent", native_name: "Genitorialità 👨‍👩‍👧‍👦" },
      { name: "travel", native_name: "Viaggi 🌍" },
      { name: "fashion", native_name: "Moda 👗" },
      { name: "healthfitness", native_name: "Salute e Fitness 🧘‍♂️" },
      { name: "luxury", native_name: "Lusso 💎" },
      { name: "adventure", native_name: "Avventura 🌄" },
      { name: "cosmetics", native_name: "Bellezza / Cosmetici 💄" },
      { name: "business", native_name: "Affari 💼" },
      { name: "fashiondesign", native_name: "Design di Moda 👠" },
      { name: "books", native_name: "Libri 📚" },
      { name: "shopping", native_name: "Shopping e Moda 🛍️" },
      { name: "mom", native_name: "Madre e Bambino 👩‍👧‍👦" },
      { name: "gastronomy", native_name: "Gastronomia / Cibo 🍽️" },
      { name: "animal", native_name: "Adozione di Animali 🐾" },
    ],
    group_name: "lifestyle",
    native_name: "Stile di vita",
  },
  {
    items: [
      { name: "computer", native_name: "Computer 💻" },
      { name: "cars", native_name: "Auto 🚗" },
      { name: "development", native_name: "Software 💾" },
      { name: "games", native_name: "Videogiochi 🎮" },
      { name: "bitcoin", native_name: "Criptovaluta 💰" },
    ],
    group_name: "technology",
    native_name: "Tecnologia",
  },
]

const hobbieListZH = [
  {
    items: [
      { name: "basketball", native_name: "篮球 🏀" },
      { name: "football", native_name: "足球 ⚽" },
      { name: "tennis", native_name: "网球 🎾" },
      { name: "swim", native_name: "游泳 🏊" },
      { name: "cycling", native_name: "骑行 🚴" },
      { name: "boxing", native_name: "拳击 🥊" },
      { name: "handball", native_name: "手球 🤾" },
      { name: "golf", native_name: "高尔夫 ⛳" },
      { name: "ski", native_name: "滑雪 ⛷️" },
      { name: "fitness", native_name: "健身与健康 💪" },
      { name: "chess", native_name: "国际象棋 ♟️" },
    ],
    group_name: "sport",
    native_name: "运动",
  },
  {
    items: [
      { name: "photography", native_name: "摄影 📷" },
      { name: "painting", native_name: "绘画 🎨" },
      { name: "music", native_name: "音乐 🎵" },
      { name: "cinema", native_name: "电影 🎬" },
      { name: "literature", native_name: "文学 📚" },
      { name: "theater", native_name: "戏剧 🎭" },
    ],
    group_name: "art",
    native_name: "艺术",
  },
  {
    items: [
      { name: "parent", native_name: "育儿 👨‍👩‍👧‍👦" },
      { name: "travel", native_name: "旅行 🌍" },
      { name: "fashion", native_name: "时尚 👗" },
      { name: "healthfitness", native_name: "健康与健身 🧘‍♂️" },
      { name: "luxury", native_name: "奢华 💎" },
      { name: "adventure", native_name: "冒险 🌄" },
      { name: "cosmetics", native_name: "美容/化妆品 💄" },
      { name: "business", native_name: "商务 💼" },
      { name: "fashiondesign", native_name: "时尚设计 👠" },
      { name: "books", native_name: "书籍 📚" },
      { name: "shopping", native_name: "购物与时尚 🛍️" },
      { name: "mom", native_name: "母子 👩‍👧‍👦" },
      { name: "gastronomy", native_name: "美食 🍽️" },
      { name: "animal", native_name: "宠物领养 🐾" },
    ],
    group_name: "lifestyle",
    native_name: "生活方式",
  },
  {
    items: [
      { name: "computer", native_name: "电脑 💻" },
      { name: "cars", native_name: "汽车 🚗" },
      { name: "development", native_name: "软件 💾" },
      { name: "games", native_name: "电子游戏 🎮" },
      { name: "bitcoin", native_name: "加密货币 💰" },
    ],
    group_name: "technology",
    native_name: "科技",
  },
]

export {
  hobbiesList,
  hobbieListEN,
  hobbieListDE,
  hobbieListAR,
  hobbieListES,
  hobbieListFR,
  hobbieListHI,
  hobbieListIT,
  hobbieListZH,
}
