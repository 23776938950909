import { hobbiesList } from "../static/Hobbies"

interface IHobbiesGenerator {
  data: string[]
}
export const HobbiesGenerator = ({ data }: IHobbiesGenerator): string[] => {
  const userNativeHobbies: string[] = []

  hobbiesList.forEach((group) => {
    group.items.forEach((item) => {
      if (data.includes(item.name)) {
        userNativeHobbies.push(item.native_name)
      }
    })
  })
  return userNativeHobbies
}
