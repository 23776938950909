import React from "react"
import { useEffect, useState } from "react"
import { DataTable, DataTableSortStatus } from "mantine-datatable"
import sortBy from "lodash/sortBy"
import DownloadCSVButton from "../components/DownloadCSVButton"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye } from "@fortawesome/free-solid-svg-icons"
import { TAgetAllApplications } from "../services/applicationAPI"
import { Link } from "react-router-dom"

const fetchData = async (
  page: number,
  perPage: number,
  params: any,
  sortBy: string,
  sortOrder: boolean,
) => {
  try {
    const response = await TAgetAllApplications(page, perPage, params, sortBy, sortOrder)
    if (response && Array.isArray(response.applications)) {
      const totalPages = response.totalPages
      const data = response.applications.map((item: any, index: any) => {
        return {
          id: index + 1,
          _id: item._id,
          campaign: item.campaign,
          user: item.user,
          instauser: item.instauser,
          state: item.state,
          price_user: item.price_user,
          price_brand: item.price_brand,
          state_payload: item.state_payload,
          state_history: item.state_history,
          last_action_at: item.last_action_at,
          application_date: item.application_date,
          insta_last_scrape: item.insta_last_scrape,
          insta_id: item.insta_id,
          insta_username: item.insta_username,
          insta_full_name: item.insta_full_name,
          insta_biography: item.insta_biography,
          insta_profile_pic: item.insta_profile_pic,
          insta_followers: item.insta_followers,
          insta_following: item.insta_following,
          insta_average_like: item.insta_average_like,
          insta_post_number: item.insta_post_number,
          insta_engagement_rate: item.insta_engagement_rate,
          insta_shared_posts: item.insta_shared_posts,
          birthday: item.birthday,
          age: item.age,
          gender: item.gender,
          job: item.job,
          score: item.score,
          school_type: item.school_type,
          school_name: item.school_name,
          city: item.city,
          country: item.country,
          language: item.language,
          currency: item.currency,
          hobbies: item.hobbies,
          insta_keywords: item.insta_keywords,
          tiktok_username: item.tiktok_username,
          tiktok_nickname: item.tiktok_nickname,
          tiktok_last_scrape: item.tiktok_last_scrape,
          tiktok_followers: item.tiktok_followers,
          tiktok_following: item.tiktok_following,
          tiktok_hearts: item.tiktok_hearts,
          tiktok_videos: item.tiktok_videos,
          tiktok_post_number: item.tiktok_post_number,
          tiktok_profile_pic: item.tiktok_profile_pic,
          tiktok_createTime: item.tiktok_createTime,
          tiktok_privateAccount: item.tiktok_privateAccount,
          tiktok_verified: item.tiktok_verified,
          tiktok_secret: item.tiktok_secret,
          tiktok_average_like: item.tiktok_average_like,
          tiktok_engagement_rate: item.tiktok_engagement_rate,
          tiktok_signature: item.tiktok_signature,
          verification: item.verification,
          ...item,
        }
      })
      return { data, totalPages }
    }
  } catch (error: any) {
    throw new Error(error)
  }
}

const AllApplications = () => {
  const [userData, setUserData] = useState([])
  const [page, setPage] = useState(1)
  const PAGE_SIZES = [10, 20, 30, 50, 100]
  const [pageSize, setPageSize] = useState(PAGE_SIZES[2])
  const [totalPages, setTotalPages] = useState(0)
  const [initialRecords, setInitialRecords] = useState(sortBy(userData, "id"))
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({
    columnAccessor: "id",
    direction: "asc",
  })
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const [sortByColumn, setSortByColumn] = useState("")
  const [sortOrder, setSortOrder] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)

  const handleFetchData = async () => {
    setLoading(true)
    const flattenFilters = Object.entries(filters).reduce((acc, [key, filter]) => {
      if (key === "insta_username" && typeof filter === "string") {
        acc[key] = filter
      } else if (key === "tiktok_username" && typeof filter === "string") {
        acc[key] = filter
      } else if (key === "campaign" && typeof filter === "string") {
        acc[key] = filter
      } else if (key === "user" && typeof filter === "string") {
        acc[key] = filter
      }

      return acc
    }, {} as { [key: string]: string })

    const params = new URLSearchParams(flattenFilters)
    try {
      const data = await fetchData(page, pageSize, params, sortByColumn, sortOrder)
      if (data !== undefined) {
        setUserData(data.data)
        setInitialRecords(data.data)
        setTotalPages(data.totalPages)
      } else {
        setError("No data found")
      }
      setLoading(false)
    } catch (error) {
      setError(`Error fetching data, ${error}`)
    }
  }

  useEffect(() => {
    handleFetchData()
  }, [page, pageSize, sortByColumn, sortOrder])

  const handleSorting = (sortyBy: string) => {
    setSortByColumn(sortyBy)
    setSortOrder((prevOrder) => !prevOrder)
  }

  const renderStatePayload = (application: any) => {
    const toggleExpandedRow = () => {
      setIsExpanded(!isExpanded)
    }
    if (isExpanded) {
      return (
        <div>
          {application.state_payload}
          <button
            type="button"
            onClick={toggleExpandedRow}
            className="text-blue-500 hover:underline"
          >
            Less
          </button>
        </div>
      )
    } else if (application.state_payload?.length > 50) {
      return (
        <div>
          {`${application.state_payload.slice(0, 50)}... `}
          <button
            type="button"
            onClick={toggleExpandedRow}
            className="text-blue-500 hover:underline"
          >
            More
          </button>
        </div>
      )
    } else {
      return <div>{application.state_payload}</div>
    }
  }

  const defaultState = {
    insta_username: "",
    tiktok_username: "",
    campaign: "",
    user: "",
  }
  const [filters, setFilters] = useState(defaultState)

  const updateFilter = (key: string, value: string) => {
    if (key === "insta_username") {
      setFilters((prev) => ({ ...prev, [key]: value as string }))
    } else if (key === "tiktok_username") {
      setFilters((prev) => ({ ...prev, [key]: value as string }))
    } else if (key === "campaign") {
      setFilters((prev) => ({ ...prev, [key]: value as string }))
    } else if (key === "user") {
      setFilters((prev) => ({ ...prev, [key]: value as string }))
    }
  }

  return (
    <div className="panel">
      {error && <div className="text-red-500 text-center">{error}</div>}
      <div className="flex w-full justify-between flex-end">
        <div className="flex gap-4">
          <div>
            <input
              type="text"
              value={filters["user"]}
              onChange={(e) => {
                updateFilter("user", e.target.value)
              }}
              className="form-input w-full mb-2"
              placeholder={"Kullanıcı ID"}
            />{" "}
            <input
              type="text"
              value={filters["campaign"]}
              onChange={(e) => {
                updateFilter("campaign", e.target.value)
              }}
              className="form-input w-full mb-2"
              placeholder={"Kampanya ID"}
            />
          </div>
          <div>
            <input
              type="text"
              value={filters["insta_username"]}
              onChange={(e) => {
                updateFilter("insta_username", e.target.value)
              }}
              className="form-input w-full mb-2"
              placeholder={"IG kullanıcı adı"}
            />
            <input
              type="text"
              value={filters["tiktok_username"]}
              onChange={(e) => {
                updateFilter("tiktok_username", e.target.value)
              }}
              className="form-input w-full mb-2"
              placeholder={"TT kullanıcı adı"}
            />
          </div>
        </div>
        <div className="flex flex-row ml-auto justify-end text-center w-1/3 mb-4 mr-2">
          <button
            className=" inline-flex items-center justify-center ml-2 px-2 py-2 mt-6 mb-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 "
            onClick={() => handleFetchData()}
          >
            Kullanıcı Ara
          </button>
          <DownloadCSVButton
            className=" inline-flex items-center justify-center ml-2 px-2 py-2 mt-6 mb-2 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 "
            userData={initialRecords}
          />
        </div>
      </div>

      <div className="datatables">
        {loading ? (
          <div className="flex items-center justify-center h-40">
            <div className="animate-spin rounded-full h-10 w-10 border-t-4 border-pink-600"></div>
          </div>
        ) : (
          <DataTable
            highlightOnHover
            className="whitespace-nowrap table-hover"
            records={initialRecords}
            columns={[
              { accessor: "id", title: "Id", sortable: true },
              {
                accessor: "campaign",
                title: "Kampanya",
                sortable: true,
                render: ({ campaign }) => {
                  if (!campaign) {
                    return <div className="text-center items-center mr-4">No Campaign</div>
                  }
                  return (
                    <Link to={`/campaigns/find/${campaign}`}>
                      <div className="text-center items-center mr-4">
                        <FontAwesomeIcon icon={faEye} style={{ color: "#005eff" }} />
                      </div>
                    </Link>
                  )
                },
              },
              {
                accessor: "user",
                title: "Kullanıcı",
                sortable: true,
                render: ({ user }) => {
                  if (!user) {
                    return <div className="text-center items-center mr-4">No User</div>
                  }

                  return (
                    <Link to={`/user/find/${user}`}>
                      <div className="text-center items-center mr-4">
                        <FontAwesomeIcon icon={faEye} style={{ color: "#005eff" }} />
                      </div>
                    </Link>
                  )
                },
              },
              { accessor: "state", title: "State", sortable: true },
              {
                accessor: "state_payload",
                title: "State Payload",
                sortable: true,
                render: renderStatePayload,
              },
              { accessor: "price_user", title: "Kullanıcı Tutarı", sortable: true },
              { accessor: "price_brand", title: "Marka Tutarı", sortable: true },
              {
                accessor: "verification",
                title: "Onay Durumu",
                sortable: true,
                render: (verification) => (verification ? "True" : "False"),
              },
              {
                accessor: "insta_username",
                title: "IG Kullanıcı Adı",
                sortable: true,
              },
              { accessor: "insta_followers", title: "IG Takipçi", sortable: true },
              { accessor: "insta_average_like", title: "IG Ortalama Beğeni", sortable: true },
              { accessor: "tiktok_username", title: "TT Kullanıcı adı", sortable: true },
              { accessor: "tiktok_hearts", title: "TT Kalp Sayısı", sortable: true },
              { accessor: "tiktok_average_like", title: "TT Ortalama Beğeni", sortable: true },
              { accessor: "tiktok_engagement_rate", title: "TT Etkileşim Oranı", sortable: true },
            ]}
            totalRecords={totalPages * pageSize}
            recordsPerPage={pageSize}
            page={page}
            onPageChange={(p) => setPage(p)}
            recordsPerPageOptions={PAGE_SIZES}
            onRecordsPerPageChange={setPageSize}
            sortStatus={sortStatus}
            onSortStatusChange={(e) => handleSorting(e.columnAccessor)}
            minHeight={200}
            paginationText={({ from, to, totalRecords }) =>
              `Showing  ${from} to ${to} of ${totalRecords} entries`
            }
          />
        )}
      </div>
    </div>
  )
}

export default AllApplications
