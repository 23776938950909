import { useEffect, useState } from "react"
import React from "react"
import { TAfindApprovalUser, TAfindCountry, TAverifyUser } from "../services/userAPI"
import { SharedPostData, VideosData } from "../types/profileData"
import ReadMore from "../components/ReadMore"
import { useDispatch, useSelector } from "react-redux"
import KeywordData from "../JSON/KEYWORDS.json"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faVenus, faMars } from "@fortawesome/free-solid-svg-icons"
import {
  CountryFilterValue,
  Filters,
  FilterType,
  FilterValue,
} from "../types/waitingApprovalUserData"
import { setPageTitle } from "../redux/store/themeConfigSlice"
import {
  selectDoApprovalUserFilters,
  setDoApprovalUserFilters,
} from "../redux/store/doApprovalUserFilterSlice"

interface User {
  _id: string
  id: string
  name: string
  email: string
  age: number
  city: string
  country: string
  phone: string
  gender: string
  followers: number
  profile_complete: boolean
  post_number: string
  average_likes: number
  tiktok_followers: string
  tiktok_average_like: string
  tiktok_engagement_rate: string
  shared_posts: SharedPostData[]
  videos: VideosData[]
}

const DoApprovalScreen: React.FC = () => {
  const doApprovalUserFilters = useSelector(selectDoApprovalUserFilters)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setPageTitle("Range Search Table"))
    const initialFilters = doApprovalUserFilters
    if (
      Object.keys(initialFilters).length > 0 &&
      JSON.stringify(initialFilters) !== JSON.stringify(defaultState)
    ) {
      setFilterss(initialFilters)
    }
  }, [doApprovalUserFilters, dispatch])

  const [data, setData] = useState<User[]>([])
  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [country, setCountry] = useState("")
  const [autofillCountries, setAutofillCountries] = useState<string[]>([])
  const [keywords, setKeywords] = useState("")
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const defaultState: Filters = {
    age: { min: "", max: "" },
    followers: { min: "", max: "" },
    average_like: { min: "", max: "" },
    tiktok_followers: { min: "", max: "" },
    tiktok_average_like: { min: "", max: "" },
    tiktok_engagement_rate: { min: "", max: "" },
    country: { value: "" },
    gender: "",
    keywords: [],
    is_spam: "",
    unvisible: "",
  }

  const [filters, setFilterss] = useState<Filters>(defaultState)

  const setFilter = (key: keyof Filters, type: FilterType, value: string | string[] | "") => {
    if (key === "keywords") {
      setFilterss((prev) => ({ ...prev, [key]: value as string[] }))
      dispatch(setDoApprovalUserFilters({ ...filters }))
    } else if (key === "country") {
      setFilterss((prev) => ({ ...prev, [key]: { ...prev[key], [type]: value as string } }))
      dispatch(setDoApprovalUserFilters({ ...filters }))
    } else if (key === "gender") {
      setFilterss((prev) => ({ ...prev, [key]: value as "male" | "female" | "" }))
      dispatch(setDoApprovalUserFilters({ ...filters }))
    } else if (key === "is_spam") {
      setFilterss((prev) => ({ ...prev, [key]: value as "true" | "false" | "" }))
      dispatch(setDoApprovalUserFilters({ ...filters }))
    } else if (key === "unvisible") {
      setFilterss((prev) => ({ ...prev, [key]: value as "true" | "false" | "" }))
      dispatch(setDoApprovalUserFilters({ ...filters }))
    } else {
      setFilterss((prev) => ({ ...prev, [key]: { ...prev[key], [type]: value as string } }))
      dispatch(setDoApprovalUserFilters({ ...filters }))
    }
  }

  useEffect(() => {
    dispatch(setDoApprovalUserFilters(filters))
  }, [filters, dispatch])

  useEffect(() => {
    handleFetchData()
  }, [filters])

  const handleFetchData = async () => {
    setIsLoading(true)

    const flattenFilters = Object.entries(filters).reduce((acc, [key, filter]) => {
      if (key === "keywords") {
        console.log()
      } else if (key === "country") {
        acc[key] = (filter as CountryFilterValue).value
      } else if (key === "gender" && typeof filter === "string") {
        acc[key] = filter
      } else {
        const { min, max } = filter as FilterValue
        if (min) {
          acc[`min_${key}`] = min
        }
        if (max) {
          acc[`max_${key}`] = max
        }
      }

      return acc
    }, {} as { [key: string]: string })

    const params = new URLSearchParams(flattenFilters)

    const keywords = (filters.keywords as string[])?.map(
      (keyword) => keyword.charAt(0).toUpperCase() + keyword.slice(1),
    )
    keywords?.forEach((keywords) => {
      params.append("keywords", keywords)
    })
    try {
      const response = await TAfindApprovalUser(params)
      if (response && Array.isArray(response)) {
        setData(response)
        setIsLoading(false)
      } else {
        setError("No data found")
      }
      setIsLoading(false)
    } catch (error) {
      setError("No data found")
    }
  }

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex < data.length - 1 ? prevIndex + 1 : prevIndex))
  }

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex))
  }

  const handleApprove = async (id: string, status: string) => {
    setIsLoading(true)
    try {
      const response = await TAverifyUser(id, status)
      if (!response) {
        setError("Response is null")
        return
      }
      // setRefreshData((prev) => !prev);
      setIsLoading(false)
      if (currentIndex < data.length - 1) {
        handleNext()
      }
    } catch (error: any) {
      setError(error.message)
      setIsLoading(false)
    }
  }

  const filterKeys: (keyof Filters)[] = [
    "age",
    "followers",
    "average_like",
    "tiktok_followers",
    "tiktok_average_like",
    "tiktok_engagement_rate",
    "gender",
    "country",
    "keywords",
  ]

  const formatKey = (key: string) => {
    switch (key) {
      case "age":
        return "Yaş"
      case "followers":
        return "IG Takipçi"
      case "average_like":
        return "IG Ort Beğeni"
      case "tiktok_followers":
        return "TikTok Takipçi"
      case "tiktok_average_like":
        return "TikTok Ort Beğeni"
      case "tiktok_engagement_rate":
        return "TikTok Eng. Oranı"
      case "country":
        return "Ülke"
      case "keywords":
        return "Nesneler"
      default:
        return key
    }
  }

  const autofillCountry = async () => {
    const response = await TAfindCountry(country)
    setAutofillCountries(response)
  }

  useEffect(() => {
    if (country.length > 0) {
      autofillCountry()
    }
  }, [country])

  const handleCountrySuggestionClick = (key: any, selectedCountry: any) => {
    setFilter(key, "value", selectedCountry)
    setAutofillCountries([])
  }

  const handleInputChange = (e: any) => {
    const inputKeywords = e.target.value
      .split(" ")
      .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ")
    setKeywords(inputKeywords)

    if (inputKeywords.length === 0) {
      setIsDropdownOpen(false)
      setFilter("keywords", "value", [])
    } else {
      setIsDropdownOpen(true)
    }
  }

  const autoCompleteKeywords = keywords.split(",").map((keyword) => keyword.trim())

  const lastKeyword = autoCompleteKeywords[autoCompleteKeywords.length - 1]

  const uniqueKeywords = [...new Set(KeywordData.keywords)]
  const filteredKeywords = uniqueKeywords
    .filter((keyword: string) => keyword.toLowerCase().startsWith(lastKeyword.toLowerCase()))
    .slice(0, 4)

  const autoCompleteKeyword: string[] = autoCompleteKeywords.length === 0 ? [] : filteredKeywords

  const genderIcon = (gender: string) => {
    switch (gender) {
      case "male":
        return <FontAwesomeIcon icon={faMars} style={{ color: "#0000ff" }} />
      case "female":
        return <FontAwesomeIcon icon={faVenus} style={{ color: "#ff00dd" }} />
      default:
        return null
    }
  }

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-40">
        <div className="animate-spin rounded-full h-10 w-10 border-t-4 border-pink-600"></div>
      </div>
    )
  }
  if (error) {
    return <p>Hata: {error}</p>
  }

  return (
    <div className="flex flex-col lg:flex-col">
      <div>
        <div className=" flex md:items-center md:flex-row flex-col gap-5">
          <div className="md:flex md:flex-row w-full">
            {filterKeys.map((key) => {
              if (
                key !== "country" &&
                key !== "keywords" &&
                key !== "gender" &&
                key !== "is_spam" &&
                key !== "unvisible"
              ) {
                return (
                  <div key={key} className="md:flex md:flex-col flex-1 mb-1 mr-2">
                    <h2 className="text-sm font-bold ml-2 mb-1">{formatKey(key)}</h2>
                    <input
                      type="text"
                      value={filters[key].min}
                      onChange={(e) => {
                        setFilter(key, "min", e.target.value)
                      }}
                      className="form-input w-full mb-2"
                      placeholder={`min. ${key}`}
                    />

                    <input
                      type="text"
                      value={filters[key].max}
                      onChange={(e) => {
                        setFilter(key, "max", e.target.value)
                      }}
                      className="form-input w-full"
                      placeholder={`max. ${key}`}
                    />
                  </div>
                )
              } else if (key === "gender") {
                return (
                  <div key={key} className="md:flex md:flex-col mr-2 ml-2">
                    <h2 className="text-sm font-bold mb-2">Cinsiyet</h2>
                    <label>
                      <input
                        type="radio"
                        value="male"
                        checked={filters.gender === "male"}
                        onChange={(e) => {
                          setFilter(key, "value", e.target.value)
                        }}
                        className="form-radio text-pink-600 mr-2"
                      />
                      Erkek
                    </label>
                    <label>
                      <input
                        type="radio"
                        value="female"
                        checked={filters.gender === "female"}
                        onChange={(e) => {
                          setFilter(key, "value", e.target.value)
                        }}
                        className="form-radio text-pink-600 mr-2"
                      />
                      Kadın
                    </label>
                    <label>
                      <input
                        type="radio"
                        value=""
                        checked={filters.gender === ""}
                        onChange={(e) => {
                          setFilter(key, "value", e.target.value)
                        }}
                        className="form-radio text-pink-600 mr-2"
                      />
                      Tümü
                    </label>
                  </div>
                )
              }
            })}
          </div>
        </div>
        <div className="flex w-full justify-between flex-end">
          <div className="flex flex-row items-center w-1/3">
            <div className="md:flex md:flex-row w-3/4">
              {filterKeys.map((key) => {
                if (key === "country") {
                  return (
                    <div key={key} className="md:flex md:flex-col flex-1 mb-4 mt-3">
                      <div>
                        <h2 className="text-sm font-bold ml-2 mb-1">Ülke İsmi</h2>
                        <input
                          type="text"
                          value={filters[key].value}
                          onChange={(e) => {
                            setFilter(key, "value", e.target.value)
                            setCountry(e.target.value)
                          }}
                          className="form-input w-full"
                          placeholder={"Ülke İsmi"}
                        />
                      </div>
                      {autofillCountries.length > 0 && country.length > 0 && (
                        <ul
                          className="suggestion-list mt-16 ml-2"
                          style={{ position: "absolute", zIndex: 9999 }}
                        >
                          {[...new Set(autofillCountries)]
                            .slice(0, 5)
                            .map((autofillCountry, index) => (
                              <li
                                key={index}
                                className="bg-white p-2 text-black cursor-pointer hover:bg-gray-200"
                                onClick={() => handleCountrySuggestionClick(key, autofillCountry)}
                              >
                                {autofillCountry}
                              </li>
                            ))}
                        </ul>
                      )}
                    </div>
                  )
                } else if (key === "keywords") {
                  return (
                    <div key={key} className="md:flex md:flex-col flex-1 mb-4 mt-3 ml-2">
                      <div>
                        <h2 className="text-sm font-bold ml-2 mb-1">Nesneler</h2>
                        <input
                          type="text"
                          value={filters?.[key]?.join(",")}
                          onChange={(e) => {
                            const keywords = e.target.value.split(",").map((word) => {
                              const trimmedWord = word.trim()
                              return (
                                trimmedWord.charAt(0).toUpperCase() +
                                trimmedWord.slice(1).toLowerCase()
                              )
                            })
                            setFilter(key, "value", keywords)
                            handleInputChange(e)
                          }}
                          className="form-input w-full"
                          placeholder={"nesne1, nesne2.."}
                        />
                      </div>
                      {isDropdownOpen && keywords.length > 0 && (
                        <div>
                          <ul
                            className="suggestion-list"
                            style={{ position: "absolute", zIndex: 9999 }}
                          >
                            {autoCompleteKeyword.map((keyword, index) => (
                              <li
                                key={index}
                                className="bg-white p-2 text-black cursor-pointer hover:bg-gray-200"
                                onClick={() => {
                                  const currentInput = filters[key].join(", ")
                                  if (currentInput.includes(",")) {
                                    const parts = currentInput.split(",")
                                    parts[parts.length - 1] = keyword
                                    setFilter(
                                      key,
                                      "value",
                                      parts.map((part) => part.trim()),
                                    )
                                  } else {
                                    setFilter(key, "value", [keyword])
                                  }
                                  setIsDropdownOpen(false)
                                }}
                              >
                                {keyword}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  )
                }
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row">
        <div className="w-full lg:w-1/3">
          <div className="flex flex-row justify-between">
            <div>
              <button
                onClick={() => handleApprove(data[currentIndex]._id, "false")}
                className="mt-2 mr-2 py-2 px-6 rounded-md bg-red-500 text-white hover:bg-red-600"
              >
                Red
              </button>
              <button
                onClick={() => handleApprove(data[currentIndex]._id, "true")}
                className="mt-2 mr-2 py-2 px-6 rounded-md bg-green-500 text-white hover:bg-green-600"
              >
                Onay
              </button>
            </div>

            <div>
              <button
                onClick={handlePrevious}
                className="mt-2 mr-2 py-2 px-6 rounded-md bg-blue-500 text-white"
              >
                Geri
              </button>
              <button
                onClick={handleNext}
                className="mt-2 mr-2 py-2 px-6 rounded-md bg-blue-500 text-white"
              >
                İleri
              </button>
            </div>
          </div>
          {data.length > 0 && (
            <div className="datatables">
              <div className="w-full max-w-xl mt-4 rounded-md shadow-sm ">
                <table className="table-auto w-full text-sm">
                  <tbody>
                    {Object.entries(data[currentIndex]).map(([key, value]) => {
                      if (
                        key !== "id" &&
                        key !== "shared_posts" &&
                        key !== "videos" &&
                        key !== "money"
                      ) {
                        return (
                          <tr key={key}>
                            <td className="font-medium pr-2 p-0">
                              {key.charAt(0).toUpperCase() + key.slice(1)}:
                            </td>
                            <td className="text-gray-700 p-0">
                              {key === "gender" ? genderIcon(value as string) : String(value)}
                            </td>
                          </tr>
                        )
                      }
                      return null
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
        <div className="w-full lg:w-2/3 h-screen mt-4 lg:mt-0 p-10">
          <div className="grid grid-cols-3 md:grid-cols-3 lg:grid-cols-3 gap-1">
            {data.length > 0 &&
              data[currentIndex].shared_posts &&
              data[currentIndex].shared_posts.map((post: SharedPostData) => (
                <div
                  key={post._id}
                  className="flex flex-col bg-white rounded-lg shadow-sm overflow-hidden max-w-xs mx-2 mb-2"
                >
                  <img
                    className="h-32 w-full object-cover zoom"
                    src={post.media_url}
                    alt={post.description}
                  />
                  <div className="p-2">
                    {post.location && post.location.name && (
                      <div className="font-semibold text-md mb-1">{post.location.name}</div>
                    )}
                    {post.description && post.description.length > 150 ? (
                      <ReadMore content={post.description} maxCharacterCount={50} />
                    ) : (
                      <p className="text-gray-700 text-sm">{post.description}</p>
                    )}
                  </div>
                  <div className="p-2">
                    <span className="inline-block bg-gray-200 rounded-full px-2 py-0.5 text-xs font-semibold text-gray-700 mr-1 mb-1">
                      Beğeniler: {post.like_count}
                    </span>
                    <span className="inline-block bg-gray-200 rounded-full px-2 py-0.5 text-xs font-semibold text-gray-700 mr-1 mb-1">
                      Yorumlar: {post.comment_count}
                    </span>
                  </div>
                </div>
              ))}

            {data.length > 0 &&
              data[currentIndex].videos &&
              data[currentIndex].videos.map((video: VideosData) => (
                <div
                  key={video._id}
                  className="flex flex-col bg-white rounded-lg shadow-sm overflow-hidden max-w-xs mx-2 mb-2"
                >
                  <img
                    className="h-32 w-full object-cover zoom"
                    src={video.cover}
                    alt={video.description}
                  />
                  <div className="p-2">
                    <div className="font-semibold text-md mb-1">{video.music}</div>
                    {video.description && video.description.length > 150 ? (
                      <ReadMore content={video.description} maxCharacterCount={50} />
                    ) : (
                      <p className="text-gray-700 text-sm">{video.description}</p>
                    )}
                  </div>
                  <div className="p-2">
                    <span className="inline-block bg-gray-200 rounded-full px-2 py-0.5 text-xs font-semibold text-gray-700 mr-1 mb-1">
                      Beğeniler: {video.like_count}
                    </span>
                    <span className="inline-block bg-gray-200 rounded-full px-2 py-0.5 text-xs font-semibold text-gray-700 mr-1 mb-1">
                      Yorumlar: {video.comment_count}
                    </span>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DoApprovalScreen
