import {
  ShoppingBagIcon,
  BriefcaseIcon,
  HeartIcon,
  HomeIcon,
  MusicalNoteIcon,
  CameraIcon,
  GlobeAltIcon,
  AcademicCapIcon,
  LightBulbIcon,
  SparklesIcon,
  ComputerDesktopIcon,
  WrenchScrewdriverIcon,
  FireIcon,
  BoltIcon,
  BeakerIcon,
  ArrowLeftStartOnRectangleIcon,
} from "@heroicons/react/20/solid"

export const BrandSectorsData = [
  { id: 1, name: "Moda ve Giyim", image: SparklesIcon, value: "fashion" },
  { id: 2, name: "Güzellik ve Kişisel Bakım", image: HeartIcon, value: "beauty_care" },
  { id: 3, name: "Teknoloji ve Elektronik", image: ComputerDesktopIcon, value: "tech_electronics" },
  { id: 4, name: "Sağlık ve Fitness", image: BeakerIcon, value: "health_fitness" },
  { id: 5, name: "Gıda ve İçecek", image: ShoppingBagIcon, value: "food_beverage" },
  { id: 6, name: "Otomotiv", image: BoltIcon, value: "automotive" },
  { id: 7, name: "Ev Dekorasyon ve Mobilya", image: HomeIcon, value: "home_furniture" },
  { id: 8, name: "E-ticaret ve Perakende", image: ShoppingBagIcon, value: "ecommerce_retail" },
  { id: 9, name: "Eğlence ve Medya", image: MusicalNoteIcon, value: "entertainment_media" },
  { id: 10, name: "Seyahat ve Turizm", image: GlobeAltIcon, value: "travel_tourism" },
  { id: 11, name: "Finans ve Sigorta", image: BriefcaseIcon, value: "finance_insurance" },
  { id: 12, name: "Eğitim ve Kariyer", image: AcademicCapIcon, value: "education_career" },
  { id: 13, name: "Anne ve Bebek", image: HeartIcon, value: "mother_baby" },
  { id: 14, name: "Hayvan Bakımı ve Ürünleri", image: HeartIcon, value: "pet_care" },
  {
    id: 15,
    name: "Yemek Tarifleri ve Restoranlar",
    image: FireIcon,
    value: "food_recipes_restaurants",
  },
  {
    id: 16,
    name: "Yeşil ve Sürdürülebilir Ürünler",
    image: ArrowLeftStartOnRectangleIcon,
    value: "sustainable_products",
  },
  {
    id: 17,
    name: "Etkinlikler ve Organizasyon",
    image: SparklesIcon,
    value: "events_organization",
  },
  { id: 18, name: "Spor ve Outdoor Aktiviteleri", image: LightBulbIcon, value: "sports_outdoor" },
  { id: 19, name: "Lüks ve Premium Ürünler", image: SparklesIcon, value: "luxury_premium" },
  { id: 20, name: "Telekomünikasyon", image: BoltIcon, value: "telecommunications" },
  {
    id: 21,
    name: "Gıda Takviyeleri ve Vitaminler",
    image: BeakerIcon,
    value: "supplements_vitamins",
  },
  {
    id: 22,
    name: "Temizlik ve Hijyen Ürünleri",
    image: WrenchScrewdriverIcon,
    value: "cleaning_hygiene",
  },
  { id: 23, name: "Emlak ve Gayrimenkul", image: HomeIcon, value: "real_estate" },
  {
    id: 24,
    name: "Bireysel ve Profesyonel Eğitim",
    image: AcademicCapIcon,
    value: "individual_professional_education",
  },
  { id: 25, name: "Kişisel Gelişim ve Wellbeing", image: HeartIcon, value: "personal_development" },
  { id: 26, name: "Sanat ve El Sanatları", image: LightBulbIcon, value: "art_crafts" },
  {
    id: 27,
    name: "Çocuk ve Gençlik Ürünleri",
    image: SparklesIcon,
    value: "children_youth_products",
  },
  {
    id: 28,
    name: "Eğlence Parkları ve Oyun Alanları",
    image: MusicalNoteIcon,
    value: "amusement_parks",
  },
  {
    id: 29,
    name: "Online Eğitim ve Dijital Kurslar",
    image: AcademicCapIcon,
    value: "online_education",
  },
  {
    id: 30,
    name: "Tarım ve Hayvancılık",
    image: ArrowLeftStartOnRectangleIcon,
    value: "agriculture_livestock",
  },
  {
    id: 31,
    name: "Yeşil Enerji ve Yenilenebilir Çözümler",
    image: FireIcon,
    value: "green_energy_solutions",
  },
  {
    id: 32,
    name: "Çevre Dostu Ürünler ve Geri Dönüşüm",
    image: ArrowLeftStartOnRectangleIcon,
    value: "eco_friendly_recycling",
  },
  { id: 33, name: "Mücevher ve Aksesuar", image: SparklesIcon, value: "jewelry_accessories" },
  { id: 34, name: "İş ve Ofis Ürünleri", image: BriefcaseIcon, value: "business_office_products" },
  {
    id: 35,
    name: "Yazılım ve Mobil Uygulamalar",
    image: ComputerDesktopIcon,
    value: "software_mobile_apps",
  },
  {
    id: 36,
    name: "Fotoğrafçılık ve Görsel Medya",
    image: CameraIcon,
    value: "photography_visual_media",
  },
  { id: 37, name: "Diğer", image: CameraIcon, value: "" },
]
