import React, { useState, useEffect } from "react"
import { TAupdateApplication, TAuploadUserPayload } from "../services/applicationAPI"
import { TAfindCampaignById } from "../services/campaignsAPI"

const checkBarter = async (campaign_id: string) => {
  const campaign = await TAfindCampaignById(campaign_id)
  return campaign.barter
}

export const UpdateApplicationModal = ({
  isOpen,
  onClose,
  _id,
  campaign_id,
  state,
}: {
  isOpen: boolean
  onClose: any
  _id: string
  campaign_id: string
  state: string
}) => {
  const [error, setError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [availableStates, setAvailableStates] = useState([""])
  const [newState, setNewState] = useState("")
  const [newStatePayload, setNewStatePayload] = useState("")
  const [view, setView] = useState("")
  const [reelsLikes, setReelsLikes] = useState("")
  const [reelsComments, setReelsComments] = useState("")
  const [reelsInteractions, setReelsInteractions] = useState("")
  const [reelsSavings, setReelsSavings] = useState("")
  const [reelsSharings, setReelsSharings] = useState("")
  const [selectedStatePayloads, setSelectedStatePayloads] = useState("")
  const [selectedSocialMedia, setSelectedSocialMedia] = useState("")
  const [imageLoading, setImageLoading] = useState(false)
  const [address, setAddress] = useState({
    contactName: "",
    contactPhone: "",
    city: "",
    country: "",
    address: "",
    details: "",
    zipCode: "",
    id: "",
    extra_information: "",
  })
  const [user_id, setUser_id] = useState("")
  const [statePayloadTypes, setStatePayloadTypes] = useState([""])

  useEffect(() => {
    const fetchCampaign = async () => {
      const barter = await checkBarter(campaign_id)
      if (barter === true) {
        generateAvailableStates(state, true)
      } else {
        generateAvailableStates(state, false)
      }
    }
    fetchCampaign()
  }, [state, campaign_id])

  const generateAvailableStates = (state: string, barter: boolean) => {
    switch (state) {
      case "first_application":
        if (barter === true) {
          setAvailableStates(["waiting_address"])
        } else if (barter === false) {
          setAvailableStates(["waiting_content"])
          setStatePayloadTypes(["img"])
        } else {
          setAvailableStates(["account_rejected"])
        }
        break
      case "waiting_address":
        setAvailableStates(["address_to_approve", "brand_canceled"])
        setStatePayloadTypes(["address"])
        break
      case "address_to_approve":
        setAvailableStates(["waiting_address", "address_rejected", "waiting_content"])
        break
      case "address_rejected":
        setAvailableStates(["waiting_address", "address_to_approve"])
        break
      case "waiting_content":
        setAvailableStates([
          "content_to_share",
          "brand_canceled",
          "user_canceled",
          "waiting_address",
        ])
        setStatePayloadTypes(["img"])
        break
      case "content_to_share":
        setAvailableStates([
          "content_rejected",
          "application_done",
          "content_approved",
          "brand_canceled",
        ])
        setStatePayloadTypes(["review"])
        break
      case "content_approved":
        setAvailableStates(["content_shared", "user_canceled"])
        setStatePayloadTypes(["img"])
        break
      case "content_rejected":
        setAvailableStates(["content_to_share", "brand_canceled", "user_canceled"])
        setStatePayloadTypes(["img"])
        break
      case "content_shared":
        setAvailableStates(["application_done", "brand_canceled"])
        break
      case "brand_canceled":
        setAvailableStates([
          "first_application",
          "account_rejected",
          "waiting_address",
          "waiting_content",
          "address_to_approve",
          "address_rejected",
          "content_to_share",
          "content_rejected",
          "content_approved",
          "content_shared",
          "application_done",
          "user_canceled",
        ])
        break
      default:
        setAvailableStates([""])
    }
  }

  const handleSelectPhotoFromPC = async (type: string) => {
    try {
      const input = document.createElement("input")
      input.type = "file"
      input.accept = "*"
      input.onchange = async (e) => {
        if (!(e.target instanceof HTMLInputElement) || !e.target.files) {
          return
        }
        const file = e.target.files[0]
        const formData = new FormData()
        formData.append("user_payload", file)
        try {
          setImageLoading(true)
          const response = await TAuploadUserPayload(_id, formData, type)
          if (response.status !== 200) {
            throw new Error("Failed to upload brand logo")
          }
          setImageLoading(false)
          alert("User image uploaded successfully")
          setNewStatePayload(response.data.s3_url)
        } catch (error) {
          console.error("Error uploading photo:", error)
        }
      }
      input.click()
    } catch (error) {
      console.error("Error selecting photo:", error)
    }
  }

  const emptyBlankHandler = () => {
    if (newState === "") {
      setError(true)
      return false
    }
    setError(false)
    return true
  }

  const handleNewStateChange = () => {
    let statePayload = ""
    switch (selectedStatePayloads) {
      case "link":
        statePayload = `{"_id":"${user_id}","media_url":"${newStatePayload}"}`
        return statePayload
      case "img":
        if (selectedSocialMedia === "tiktok") {
          statePayload = `{"img":"${newStatePayload}","view":"${view}"}`
        } else if (selectedSocialMedia === "instagram") {
          statePayload = `{"img":"${newStatePayload}","view":"${view}","reelslikes":"${reelsLikes}","reelscomments":"${reelsComments}","reelsinteractions":"${reelsInteractions}","reelssavings":"${reelsSavings}","reelssharings":"${reelsSharings}"}`
        } else {
          statePayload = `{"img":"${newStatePayload}"}`
        }
        return statePayload
      case "review":
        statePayload = `{"review":"${newStatePayload}"}`
        return statePayload
      case "impressions":
        statePayload = `{"impressions":"${newStatePayload}"}`
        return statePayload
    }
  }

  const handleSubmitForm = async (e: any) => {
    e.preventDefault()
    if (!emptyBlankHandler()) {
      setIsLoading(false)
      return
    }
    const state_payload = handleNewStateChange()
    setIsLoading(true)
    address.contactPhone = `+${address.contactPhone}`
    let update = {
      state: newState,
      state_payload: state_payload,
      state_history: {
        state: state,
        state_payload: state_payload,
      },
      address: address,
    }
    try {
      await TAupdateApplication(update, _id)
      setIsLoading(false)
      onClose()
    } catch (error) {
      setIsLoading(false)
      console.error("Failed to update application", error)
    }
  }

  if (!isOpen) {
    return null
  }

  const handleStatePayload = () => {
    switch (selectedStatePayloads) {
      case "link":
        return (
          <div>
            <span className="text-gray-700">Kullanıcı ID</span>
            <input
              type="string"
              className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              value={user_id}
              onChange={(e) => {
                setUser_id(e.target.value)
              }}
            />
            <span className="text-gray-700">Medya Url</span>
            <input
              type="url"
              className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              value={newStatePayload}
              onChange={(e) => {
                setNewStatePayload(e.target.value)
              }}
            />
          </div>
        )
      case "review":
        return (
          <div>
            <span className="text-gray-700">Gözden Geçir</span>
            <input
              type="string"
              className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              value={newStatePayload}
              onChange={(e) => {
                setNewStatePayload(e.target.value)
              }}
            />
          </div>
        )
      case "img":
        return (
          <div>
            <div>
              <span className="text-gray-700">Resim/Video Url</span>
              <div className="flex mb-2">
                <input
                  type="string"
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  value={newStatePayload}
                  onChange={(e) => {
                    setNewStatePayload(e.target.value)
                  }}
                />
                <button
                  type="button"
                  className="bg-blue-500 text-white hover:bg-blue-600 rounded ml-2"
                  onClick={() => handleSelectPhotoFromPC("image")}
                >
                  Resim Seç
                </button>
                <button
                  type="button"
                  className="bg-blue-500 text-white hover:bg-blue-600 rounded ml-2"
                  onClick={() => handleSelectPhotoFromPC("video")}
                >
                  Video Seç
                </button>
              </div>
              {newState === "content_shared" && (
                <div>
                  <div>
                    <label className="block">
                      <span className="text-gray-700">Sosyal Medya Türü Seç</span>
                      <select
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 mb-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        onChange={(e) => setSelectedSocialMedia(e.target.value)}
                      >
                        <option value="">Sosyal Medya Türü Seç</option>
                        <option value={"instagram"}>Instagram</option>
                        <option value={"tiktok"}>Tiktok</option>
                      </select>
                    </label>
                  </div>
                  {selectedSocialMedia === "tiktok" && (
                    <div>
                      <span className="text-gray-700">
                        Resim İzlenme Sayısı <span className="text-xl text-red-400">*</span>
                      </span>
                      <input
                        type="string"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        value={view}
                        onChange={(e) => {
                          setView(e.target.value)
                        }}
                      />
                    </div>
                  )}
                  {selectedSocialMedia === "instagram" && (
                    <div>
                      <span className="text-gray-700">
                        Resim İzlenme Sayısı <span className="text-xl text-red-400">*</span>
                      </span>
                      <input
                        type="string"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        value={view}
                        onChange={(e) => {
                          setView(e.target.value)
                        }}
                      />
                      <span className="text-gray-700">
                        Resim Reels beğenileri <span className="text-xl text-red-400">*</span>
                      </span>
                      <input
                        type="string"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        value={reelsLikes}
                        onChange={(e) => {
                          setReelsLikes(e.target.value)
                        }}
                      />
                      <span className="text-gray-700">
                        Resim Reels Yorumları <span className="text-xl text-red-400">*</span>
                      </span>
                      <input
                        type="string"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        value={reelsComments}
                        onChange={(e) => {
                          setReelsComments(e.target.value)
                        }}
                      />
                      <span className="text-gray-700">
                        Resim Reels Etkileşimleri <span className="text-xl text-red-400">*</span>
                      </span>
                      <input
                        type="string"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        value={reelsInteractions}
                        onChange={(e) => {
                          setReelsInteractions(e.target.value)
                        }}
                      />
                      <span className="text-gray-700">
                        Resim Reels Kaydetmeleri <span className="text-xl text-red-400">*</span>
                      </span>
                      <input
                        type="string"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        value={reelsSavings}
                        onChange={(e) => {
                          setReelsSavings(e.target.value)
                        }}
                      />
                      <span className="text-gray-700">
                        Resim Reels Paylaşımları <span className="text-xl text-red-400">*</span>
                      </span>
                      <input
                        type="string"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        value={reelsSharings}
                        onChange={(e) => {
                          setReelsSharings(e.target.value)
                        }}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )
      case "address":
        return (
          <div>
            <span className="text-gray-700">
              Contact Name <span className="text-xxs text-red-500">*required</span>
            </span>
            <input
              type="string"
              className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              placeholder="John Doe"
              value={address.contactName}
              onChange={(e) => {
                setAddress({ ...address, contactName: e.target.value })
              }}
            />
            <span className="text-gray-700">Contact Phone</span>
            <input
              type="string"
              className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              placeholder="905554441212"
              value={address.contactPhone}
              onChange={(e) => {
                setAddress({ ...address, contactPhone: e.target.value })
              }}
            />
            <span className="text-gray-700">
              City <span className="text-xxs text-red-500">*required</span>
            </span>
            <input
              type="string"
              className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              value={address.city}
              placeholder="Istanbul"
              onChange={(e) => {
                setAddress({ ...address, city: e.target.value })
              }}
            />
            <span className="text-gray-700">
              Country <span className="text-xxs text-red-500">*required</span>
            </span>
            <input
              type="string"
              className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              placeholder="Turkey"
              value={address.country}
              onChange={(e) => {
                setAddress({ ...address, country: e.target.value })
              }}
            />
            <span className="text-gray-700">
              Address <span className="text-xxs text-red-500">*required</span>
            </span>
            <input
              type="string"
              className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              value={address.address}
              onChange={(e) => {
                setAddress({ ...address, address: e.target.value })
              }}
            />
            <span className="text-gray-700">Details</span>
            <input
              type="string"
              className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              placeholder="Near the park"
              value={address.details}
              onChange={(e) => {
                setAddress({ ...address, details: e.target.value })
              }}
            />
            <span className="text-gray-700">Zip Code</span>
            <input
              type="string"
              className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              placeholder="34000"
              value={address.zipCode}
              onChange={(e) => {
                setAddress({ ...address, zipCode: e.target.value })
              }}
            />
            <span className="text-gray-700">Extra Information</span>
            <input
              type="string"
              className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              value={address.extra_information}
              onChange={(e) => {
                setAddress({ ...address, extra_information: e.target.value })
              }}
            />
          </div>
        )
    }
  }

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-8 rounded-lg shadow-lg overflow-y-auto h-5/6 max-w-md w-full space-y-4">
        {imageLoading ? (
          <div className="flex items-center justify-center h-40">
            <div className="animate-spin rounded-full h-10 w-10 border-t-4 border-pink-600"></div>
          </div>
        ) : (
          <>
            <h2 className="text-xl font-semibold mb-4">Update Application</h2>
            <form onSubmit={handleSubmitForm} className="space-y-4">
              {/* Other form fields */}
              <label className="block">
                <span className="text-gray-700">Select New Application State</span>
                <select
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 mb-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  onChange={(e) => setNewState(e.target.value)}
                >
                  <option value="">Select New State</option>
                  {availableStates.map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
              </label>

              <label className="block">
                <div>
                  <span className="text-gray-700">Select State Payload Type</span>
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 mb-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    onChange={(e) => {
                      setSelectedStatePayloads(e.target.value)
                    }}
                  >
                    <option value="">Select State Payload Type</option>
                    {statePayloadTypes.map((statePayload) => (
                      <option key={statePayload} value={statePayload}>
                        {statePayload}
                      </option>
                    ))}
                  </select>
                </div>
                {selectedStatePayloads && handleStatePayload()}
              </label>

              {error && <p className="text-red-500">Please fill out all fields</p>}
              {/* Other form fields */}
              <div className="flex justify-end space-x-4">
                <button
                  type="button"
                  onClick={onClose}
                  className="px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={isLoading}
                  className="px-4 py-2 bg-blue-500 text-white hover:bg-blue-600 rounded"
                >
                  {isLoading ? "Loading..." : "Save"}
                </button>
              </div>
            </form>
          </>
        )}
      </div>
    </div>
  )
}
