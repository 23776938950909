import React, { useState } from "react"

interface EditSubCategoryProps {
  subCategory: string[]
  setSubCategory: React.Dispatch<React.SetStateAction<string[]>>
  onClose: () => void
  handleSave: () => void
}

const EditSubCategoryModal: React.FC<EditSubCategoryProps> = ({
  subCategory,
  setSubCategory,
  onClose,
  handleSave,
}) => {
  const [currentInput, setCurrentInput] = useState<string>("")

  const handleAddSubCategory = () => {
    if (currentInput.trim() !== "") {
      setSubCategory([...subCategory, currentInput.trim()])
      setCurrentInput("")
    }
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-4 rounded shadow-lg w-full max-w-xs mx-auto">
        <h2 className="text-lg font-bold mb-4">Alt Kategoriyi Düzenle</h2>
        <div className="flex items-center gap-2">
          <input
            type="text"
            value={currentInput}
            onChange={(e) => setCurrentInput(e.target.value)}
            className="flex-1 border border-gray-300 rounded px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:outline-none"
            placeholder="Add a sub-category"
          />
          <button
            className="bg-green-500 text-white px-3 py-2 rounded hover:bg-green-600"
            onClick={handleAddSubCategory}
          >
            Ekle
          </button>
        </div>
        <ul className="mt-4">
          {subCategory.map((item, index) => (
            <li key={index} className="flex justify-between items-center">
              <span>{item}</span>
              <button
                className="text-red-500 hover:text-red-700"
                onClick={() => setSubCategory(subCategory.filter((_, i) => i !== index))}
              >
                Kaldır
              </button>
            </li>
          ))}
        </ul>
        <div className="flex justify-between mt-4">
          <button
            className="bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700"
            onClick={() => {
              handleSave()
              onClose()
            }}
          >
            Kaydet
          </button>
          <button
            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
            onClick={onClose}
          >
            Kapat
          </button>
        </div>
      </div>
    </div>
  )
}

export default EditSubCategoryModal
