import React from "react"
import { BrandSectorsData } from "../types/brandSectorData"

interface EditCategoryProps {
  category: string
  setCategory: React.Dispatch<React.SetStateAction<string>>
  onClose: () => void
  handleSave: () => void
}

const EditCategoryModal: React.FC<EditCategoryProps> = ({
  category,
  setCategory,
  onClose,
  handleSave,
}) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-4 rounded shadow-lg w-full max-w-xs mx-auto">
        <h2 className="text-lg font-bold mb-4">Kategoriyi Düzenle</h2>
        {/* Dropdown (select) */}
        <select
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          className="w-full border border-gray-300 rounded px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:outline-none"
        >
          <option value="" disabled>
            Kategori Seçin
          </option>
          {BrandSectorsData.map((sector) => (
            <option key={sector.id} value={sector.value}>
              {sector.name}
            </option>
          ))}
        </select>
        <div className="flex justify-between mt-4">
          <button
            className="bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700"
            onClick={() => {
              handleSave()
              onClose()
            }}
          >
            Kaydet
          </button>
          <button
            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
            onClick={onClose}
          >
            Kapat
          </button>
        </div>
      </div>
    </div>
  )
}

export default EditCategoryModal
