import React, { useEffect, useState } from "react"
import { CampaignType, Limitations, ApplicationCounts } from "../types/campaignsData"
import "./styles/styles.css"
import {
  TAupdateCampaignNotes,
  TAupdateCampaign,
  TAupdateCampaignLogo,
  TAcampaignLimitationApplications,
} from "../services/campaignsAPI"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash } from "@fortawesome/free-solid-svg-icons"
import UserCard from "./UserCard"
import AddApplicationModal from "./AddApplicationModal"

export const CampaignProfile = (data: CampaignType) => {
  const [_id, set_Id] = useState("")
  const [name, setName] = useState("")
  const [country, setCountry] = useState("")
  const [description, setDescription] = useState("")
  const [coverPhoto, setCoverPhoto] = useState("")
  const [platform, setPlatform] = useState("")
  const [campaignNotes, setCampaignNotes] = useState([""])
  const [notes, setNotes] = useState("")
  const [isVerified, setIsVerified] = useState(Boolean)
  const [visibility, setVisibility] = useState(Boolean)
  const [rejectedReason, setRejectedReason] = useState("")
  const [limitations, setLimitations] = useState<Limitations>({
    gender: "male",
    min_follower: 0,
    max_follower: 0,
    min_age: 0,
    max_age: 0,
    school: "",
    city: "",
  })
  const [applicationCounts, setApplicationCounts] = useState<ApplicationCounts>({
    first_application: 0,
    waiting_content: 0,
    content_offered: 0,
    content_to_share: 0,
    content_rejected: 0,
    content_shared: 0,
    content_approved: 0,
    account_rejected: 0,
    brand_canceled: 0,
    user_canceled: 0,
    application_done: 0,
  })
  const [isOpen, setIsOpen] = useState(false)
  const [logo_url, setLogo_url] = useState("")
  const [isBarterDropdownOpen, setIsBarterDropdownOpen] = useState(false)
  const [barter, setBarter] = useState(false)
  const [barterDetails, setBarterDetails] = useState("")
  const [barterDescription, setBarterDescription] = useState("")
  const [suggestedUsers, setSuggestedUsers] = useState([])
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [totalPages, setTotalPages] = useState(0)
  const [isApplicationModalOpen, setIsApplicationModalOpen] = useState(false)

  const goToPreviousPage = () => {
    if (page > 1) {
      setPage(page - 1)
    }
  }

  const goToNextPage = () => {
    if (page < totalPages) {
      setPage(page + 1)
    }
  }

  const handleCampaignLimitationApplications = async (
    id: string,
    page: number,
    perPage: number,
  ) => {
    try {
      const response = await TAcampaignLimitationApplications(id, page, perPage)
      if (response) {
        setSuggestedUsers(response.data.users)
        setTotalPages(response.data.totalPages)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    handleCampaignLimitationApplications(data._id, page, perPage)
  }, [data, page, perPage])

  useEffect(() => {
    set_Id(data?._id ?? "")
    setName(data?.name ?? "")
    setCountry(data?.country ?? "")
    setDescription(data?.description ?? "")
    setCoverPhoto(data?.cover_photo ?? "")
    setPlatform(data?.platform ?? "")
    setCampaignNotes(data?.notes ?? [""])
    setIsVerified(data?.is_verified ?? Boolean)
    setVisibility(data?.visibility ?? Boolean)
    setRejectedReason(data?.rejected_reason ?? "")
    setLimitations(
      data?.limitations ?? {
        gender: "",
        min_age: 0,
        max_age: 0,
        min_follower: 0,
        max_follower: 0,
        school: "",
        city: "",
      },
    )
    setApplicationCounts(
      data?.application_counts ?? {
        first_application: 0,
        waiting_content: 0,
        content_offered: 0,
        content_to_share: 0,
        content_rejected: 0,
        content_shared: 0,
        content_approved: 0,
        account_rejected: 0,
        brand_canceled: 0,
        user_canceled: 0,
        application_done: 0,
      },
    )
  }, [data])

  const handleUpdateNote = async (campaignNotes: any) => {
    try {
      const campaign: any = await TAupdateCampaignNotes(_id, campaignNotes) // ! UPDATE TYPES
      if (campaign) {
        alert("Note updated successfully")
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleDeleteNote = (index: any) => {
    const newNotes = campaignNotes.filter((note, noteIndex) => noteIndex !== index)
    handleUpdateNote(newNotes)
  }

  const handleUploadPhoto = async (logo_url: any) => {
    try {
      TAupdateCampaign(_id, { cover_photo: logo_url })
      setIsOpen(false)
    } catch (error) {
      console.error(error)
    }
  }

  const handleSelectPhotoFromPC = async () => {
    try {
      const input = document.createElement("input")
      input.type = "file"
      input.accept = "image/*"
      input.onchange = async (e) => {
        if (!(e.target instanceof HTMLInputElement) || !e.target.files) {
          return
        }

        const file = e.target.files[0]
        const formData = new FormData()
        formData.append("cover_photo", file)

        try {
          const response = await TAupdateCampaignLogo(_id, formData)
          if (response.status !== 200) {
            throw new Error("Failed to upload cover photo")
          }

          alert("Cover Photo updated successfully")
        } catch (error) {
          console.error("Error uploading photo:", error)
        }
      }
      input.click()
    } catch (error) {
      console.error("Error selecting photo:", error)
    }
  }

  const handleUpdateBarter = async (_id: string, data: any) => {
    try {
      const res = await TAupdateCampaign(_id, {
        barter: barter,
        barter_details: barterDetails,
        barter_description: barterDescription,
      })
      if (res) {
        alert("Barter updated successfully")
      }
      setIsBarterDropdownOpen(false)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div>
      <div className="bg-gradient-to-rt from-teal-50 to-blue-50 p-6 rounded-lg shadow-lg max-w-6xl mx-auto mb-12">
        <h3 className="text-2xl font-semibold mb-4 text-gray-800">Campaign Information</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
          <div className="bg-white p-4 rounded-lg shadow border border-gray-200">
            {coverPhoto && (
              <div className="flex items-center">
                <img src={coverPhoto} alt="Brand Logo" className="w-28 h-28 rounded-full mr-4" />
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  onClick={() => setIsOpen(true)}
                >
                  Resmi Değiştir
                </button>
              </div>
            )}
            {!coverPhoto && (
              <div
                className="w-20 h-20 rounded-full bg-gray-300 flex items-center justify-center text-gray-600 text-sm font-bold cursor-pointer"
                onClick={() => setIsOpen(true)}
              >
                Resim Ekle
              </div>
            )}
            {isOpen && (
              <div className="mt-2">
                <input
                  type="text"
                  placeholder="Enter photo url"
                  value={logo_url}
                  onChange={(e) => setLogo_url(e.target.value)}
                  className="border border-gray-400 rounded py-2 px-4 mb-2"
                />
                <button
                  className="bg-blue-500 ml-2 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  onClick={() => handleUploadPhoto(logo_url)}
                >
                  Kaydet
                </button>
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 ml-2 rounded"
                  onClick={() => handleSelectPhotoFromPC()}
                >
                  Bilgisayardan Yükle
                </button>
              </div>
            )}
            <table className="table-auto w-full">
              <tbody>
                <tr className="border-b">
                  <td className="py-2 font-semibold text-gray-700">Kampanya Id</td>
                  <td className="py-2 text-gray-600">{data._id}</td>
                </tr>
                <tr className="border-b">
                  <td className="py-2 font-semibold text-gray-700">Ülke</td>
                  <td className="py-2 text-gray-600">{data.country}</td>
                </tr>
                <tr className="border-b">
                  <td className="py-2 font-semibold text-gray-700">Platform</td>
                  <td className="py-2 text-gray-600">{data.platform}</td>
                </tr>
                <tr className="border-b">
                  <td className="py-2 font-semibold text-gray-700">Görünürlük</td>
                  <td className="py-2 text-gray-600">{data.visibility ? "Visible" : "Hidden"}</td>
                </tr>
                <tr>
                  <td className="py-2 font-semibold text-gray-700">Onay Durumu</td>
                  <td className="py-2 text-gray-600">{data.is_verified ? "Yes" : "No"}</td>
                </tr>
                <tr>
                  <td className="py-2 font-semibold text-gray-700">Sektör</td>
                  <td className="py-2 text-gray-600">{data.sector}</td>
                </tr>
                <tr>
                  <td className="py-2 font-semibold text-gray-700">Alt Sektör</td>
                  <td className="py-2 text-gray-600">{data.sub_sector}</td>
                </tr>
                <tr>
                  <td className="py-2 font-semibold text-gray-700">Ürün Gönderimi</td>
                  <td className="py-2 text-gray-600">{data.barter === true ? "True" : "False"}</td>
                  <button
                    className="bg-blue-500 text-white rounded-md py-2 mt-1 w-full"
                    onClick={() => setIsBarterDropdownOpen(true)}
                  >
                    Barter güncelle
                  </button>
                </tr>
                {data.barter && (
                  <>
                    <tr>
                      <td className="py-2 font-semibold text-gray-700">Ürün Gönderim Açıklaması</td>
                      <td className="py-2 text-gray-600">{data.barter_description}</td>
                    </tr>
                    <tr>
                      <td className="py-2 font-semibold text-gray-700">Ürün Gönderim Detayları</td>
                      <td className="py-2 text-gray-600">{data.barter_details}</td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
            {isBarterDropdownOpen && (
              <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                <div className="bg-white p-4 rounded shadow-lg w-full max-w-xs mx-auto">
                  <h2 className="text-lg font-bold mb-4">Kampanya Ürün Gönderimini Güncelle</h2>
                  <div className="max-h-96 overflow-y-auto">
                    {" "}
                    {/* max-h-64'ü max-h-96 olarak değiştirdim */}
                    <h3 className="text-md font-bold mb-2">Ürün Gönderimi</h3>
                    <div className="flex flex-row">
                      <label className="flex items-center cursor-pointer">
                        <input
                          type="radio"
                          className="form-radio h-5 w-5 text-blue-600"
                          name="barter"
                          value="true"
                          onChange={() => setBarter(true)}
                        />
                        <span className="text-gray-700">Evet</span>
                      </label>
                      <label className="flex items-center cursor-pointer ml-4">
                        <input
                          type="radio"
                          className="form-radio h-5 w-5 text-blue-600"
                          name="barter"
                          value="true"
                          onChange={() => setBarter(false)}
                        />
                        <span className="text-gray-700">Hayır</span>
                      </label>
                    </div>
                    <h3 className="text-md font-bold mb-2 mt-6">Ürün Gönderimi Detayları</h3>
                    <label className="flex items-center cursor-pointer">
                      <textarea
                        className="w-full p-2 border border-gray-200 rounded-md"
                        placeholder="Write barter details"
                        value={barterDetails}
                        onChange={(e) => setBarterDetails(e.target.value)}
                      />
                    </label>
                    <h3 className="text-md font-bold mb-2">Ürün Gönderimi Açıklaması</h3>
                    <label className="flex items-center cursor-pointer">
                      <textarea
                        className="w-full p-2 border border-gray-200 rounded-md"
                        placeholder="Write barter details"
                        value={barterDescription}
                        onChange={(e) => setBarterDescription(e.target.value)}
                      />
                    </label>
                  </div>
                  <button
                    className="mt-4 bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700"
                    onClick={() => {
                      handleUpdateBarter(_id, {
                        barter: barter,
                        barter_details: barterDetails,
                        barter_description: barterDescription,
                      })
                    }}
                  >
                    Kaydet
                  </button>
                  <button
                    className="mt-4 ml-12 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
                    onClick={() => setIsBarterDropdownOpen(false)}
                  >
                    Kapat
                  </button>
                </div>
              </div>
            )}
          </div>

          <div className="col-span-1 md:col-span-1">
            <div className="bg-white p-4 rounded-lg shadow border border-gray-200 overflow-hidden">
              <h4 className="text-lg font-semibold mb-4 text-gray-700">İçerik Metrikleri</h4>
              <table className="table-auto w-full">
                <tbody>
                  <tr className="border-b">
                    <td className="py-2 text-gray-600 truncate">İçerik Detayları</td>
                    <td className="py-2 text-gray-800 break-words">
                      {Array.isArray(data.content_details)
                        ? data.content_details.join(", ")
                        : data.content_details}
                    </td>
                  </tr>
                  <tr className="border-b">
                    <td className="py-2 text-gray-600 truncate">
                      İçerikte Bahsedilmesi Gerekenler
                    </td>
                    <td className="py-2 text-gray-800 break-words">
                      {Array.isArray(data.content_mentions)
                        ? data.content_mentions.join(", ")
                        : data.content_mentions}
                    </td>
                  </tr>
                  <tr className="border-b">
                    <td className="py-2 text-gray-600 truncate">İçerik Örnek Metni</td>
                    <td className="py-2 text-gray-800 break-words">
                      {Array.isArray(data.content_example_text)
                        ? data.content_example_text.join(", ")
                        : data.content_example_text}
                    </td>
                  </tr>
                  <tr className="border-b">
                    <td className="py-2 text-gray-600 truncate">İçerik Metrikleri</td>
                    <td className="py-2 text-gray-800 break-words">
                      {Array.isArray(data.content_metrics)
                        ? data.content_metrics.join(", ")
                        : data.content_metrics}
                    </td>
                  </tr>
                  <tr className="border-b">
                    <td className="py-2 text-gray-600 truncate">
                      İçerikte Bahsedilmemesi Gerekenler
                    </td>
                    <td className="py-2 text-gray-800 break-words">
                      {Array.isArray(data.mention_awoid)
                        ? data.mention_awoid.join(", ")
                        : data.mention_awoid}
                    </td>
                  </tr>
                  <tr className="border-b">
                    <td className="py-2 text-gray-600 truncate">İçerik Bahsedilen Vurgusu</td>
                    <td className="py-2 text-gray-800 break-words">
                      {Array.isArray(data.mention_emphasis)
                        ? data.mention_emphasis.join(", ")
                        : data.mention_emphasis}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2 text-gray-600 truncate">İçerik Hashtagleri</td>
                    <td className="py-2 text-gray-800 break-words">
                      {Array.isArray(data.content_hashtags)
                        ? data.content_hashtags.join(", ")
                        : data.content_hashtags}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2 text-gray-600 truncate">İçerik Hesabı</td>
                    <td className="py-2 text-gray-800 break-words">
                      {Array.isArray(data.content_account)
                        ? data.content_account.join(", ")
                        : data.content_account}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2 text-gray-600 truncate">İçerik Promosyonları</td>
                    <td className="py-2 text-gray-800 break-words">
                      {Array.isArray(data.content_promotions)
                        ? data.content_promotions.join(", ")
                        : data.content_promotions}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <DetailItem label="Açıklama" value={data.description} />

          {/* Limitations */}
          <div className="col-span-1 md:col-span-1">
            <div className="bg-white p-4 rounded-lg shadow border border-gray-200">
              <h4 className="text-lg font-semibold mb-4 text-gray-700">Segmentasyon</h4>
              <table className="table-auto w-6/12">
                <tbody>
                  <tr className="border-b">
                    <td className="py-2">Cinsiyet</td>
                    <td className="py-2">
                      {data.limitations.gender ? data.limitations.gender : "Any"}
                    </td>
                  </tr>
                  <tr className="border-b">
                    <td className="py-2">Yaş</td>
                    <td className="py-2">
                      {data.limitations.min_age} to {data.limitations.max_age}
                    </td>
                  </tr>
                  <tr className="border-b">
                    <td className="py-2">Takipçi</td>
                    <td className="py-2">
                      {data.limitations.min_follower} to {data.limitations.max_follower}
                    </td>
                  </tr>
                  <tr className="border-b">
                    <td className="py-2">Okul</td>
                    <td className="py-2">{data.limitations.school || "Any"}</td>
                  </tr>
                  <tr>
                    <td className="py-2">Şehir</td>
                    <td className="py-2">{data.limitations.city || "Any"}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {/* Campaign Notes */}
          <div className="flex flex-col">
            <div className="bg-white p-4 rounded-lg shadow border border-gray-200">
              <h4 className="text-lg font-semibold mb-4 text-gray-700">Notlar</h4>
              <ul>
                {campaignNotes.map((note, index) => (
                  <li key={index} className="text-gray-600 mt-2">
                    {note}
                    <FontAwesomeIcon
                      icon={faTrash}
                      style={{ color: "#005eff", marginLeft: "8px" }}
                      onClick={() => handleDeleteNote(index)}
                    />
                  </li>
                ))}
              </ul>
              <textarea
                className="w-full mt-4 p-2 border border-gray-200 rounded-md"
                placeholder="Add a note"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              />
              <div className="flex gap-12 mt-2">
                <button
                  className="bg-blue-500 text-white rounded-md px-3 py-2 w-full"
                  onClick={() => handleUpdateNote([...campaignNotes, notes])}
                >
                  Notları Güncelle
                </button>
              </div>
            </div>
          </div>
        </div>
        <DetailItem label="Reddedilme Sebebi" value={data.rejected_reason || "-"} />
      </div>
      <div>
        {suggestedUsers.length > 0 ? (
          <div>
            <h3 className="text-2xl font-semibold mb-4 text-gray-800">Önerilen Kullanıcılar</h3>
            <div className="overflow-x-auto py-4">
              <div className="flex space-x-4">
                {suggestedUsers.map((user, index) => (
                  <UserCard
                    key={index}
                    user={user}
                    campaign_id={data._id}
                    campaign_type={data.platform}
                  />
                ))}
                <div className="flex-shrink-0 w-60 p-4 bg-white shadow-md rounded-lg border border-gray-200 hover:shadow-xl transition-shadow duration-300">
                  <div onClick={() => setIsApplicationModalOpen(true)}>
                    <img
                      src={"https://via.placeholder.com/150"}
                      alt={"asd"}
                      className="w-16 h-16 rounded-full object-cover mx-auto mb-4"
                    />
                    <div className="flex items-center justify-center mt-4">
                      <button
                        className="text-blue-600 font-semibold cursor-pointer"
                        onClick={() => setIsApplicationModalOpen(true)}
                      >
                        <h3 className="text-center text-lg font-semibold">
                          Yeni Kullanıcı Oluştur
                        </h3>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <AddApplicationModal
              isOpen={isApplicationModalOpen}
              onClose={() => setIsApplicationModalOpen(false)}
              _id={data._id}
              currency="TRY"
              platform={data.platform.includes("insta") ? "instagram" : "tiktok"}
            />
            <div className="flex justify-center items-center mt-4 space-x-4">
              <button
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 disabled:bg-gray-200 disabled:text-gray-500"
                onClick={goToPreviousPage}
                disabled={page === 1}
              >
                Önceki
              </button>
              <span className="text-gray-700">
                Page {page} of {totalPages}
              </span>
              <button
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 disabled:bg-gray-200 disabled:text-gray-500"
                onClick={goToNextPage}
                disabled={page === totalPages}
              >
                Sonraki
              </button>
            </div>
          </div>
        ) : (
          <p className="text-center text-gray-600">Önerilen Kullanıcı Yok</p>
        )}
      </div>
    </div>
  )
}

const DetailItem: React.FC<{ label: string; value: string | number }> = ({ label, value }) => (
  <div className="bg-white p-4 rounded-lg shadow border border-gray-200">
    <h5 className="text-md font-semibold mb-1 text-gray-700">{label}</h5>
    <p className="text-gray-600">{value}</p>
  </div>
)

export default CampaignProfile
