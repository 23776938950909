import React from "react"
import ReactDOM from "react-dom"

interface ModalProps {
  children: React.ReactNode
  classname: string
  classname2: string
  onClick?: (e: React.MouseEvent) => void
}

const Modal: React.FC<ModalProps> = ({ children, classname, classname2, onClick }) => {
  return ReactDOM.createPortal(
    <div className={classname} onClick={onClick}>
      <div className={classname2} onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    </div>,
    document.body,
  )
}

export default Modal
