import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import {
  TAAISearchUser,
  TAbanManyUsers,
  TAbanUser,
  TAChatGptSearchUser,
  TAfindAllUser,
  TAremoveVerification,
  TAupdateCategory,
  TAupdateSubCategory,
  TAverifyUser,
} from "../services/userAPI"
import { WaitingApprovalUserData } from "../types/waitingApprovalUserData"
import { DataTable, DataTableSortStatus } from "mantine-datatable"
import sortBy from "lodash/sortBy"
import { setPageTitle } from "../redux/store/themeConfigSlice"
import {
  Filters,
  FilterValue,
  FilterType,
  CountryFilterValue,
  CityFilterValue,
  JobFilterValue,
} from "../types/getAllUsersData"
// import DownloadPdfButton from '../components/DownloadPdfButton';
import DownloadCSVButton from "../components/DownloadCSVButton"
import KeywordData from "../JSON/KEYWORDS.json"
import HobbyData from "../JSON/HOBBIES.json"
import CountryData from "../JSON/COUNTRIES.json"
import CityData from "../JSON/CITIES.json"
import JobData from "../JSON/JOBS.json"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faVenus,
  faMars,
  faEye,
  faStar,
  faTimes,
  faBan,
  faEdit,
  faSearch,
} from "@fortawesome/free-solid-svg-icons"
import { setUserFilters, selectUserFilters } from "../redux/store/userFilterSlice"
import EditCategoryModal from "../components/EditCategoryModal"
import EditSubCategoryModal from "../components/EditSubCategoryModal"
import ProfileDetailModal from "../components/ProfileDetailModal"
import { BrandSectorsData } from "../types/brandSectorData"

const phoneNumberFixer = (phoneNumber: string) => {
  const fixedPhoneNumber = phoneNumber.slice(0, 13)
  return fixedPhoneNumber
}

const numFixer = (num: number) => {
  if (!num) {
    return 0
  }
  const roundedNumber = Math.round(num * 10) / 10
  return roundedNumber
}

const fetchData = async (
  page: number,
  perPage: number,
  query: any,
  sortBy: string,
  sortOrder: boolean,
  exportCSV: string,
) => {
  try {
    const response = await TAfindAllUser(page, perPage, query, sortBy, sortOrder, exportCSV)
    if (response && Array.isArray(response.users)) {
      const totalPages = response.totalPages
      const data = response.users.map((item: any, index: any) => {
        return {
          id: index + 1,
          name: item.name,
          email: item.email,
          age: item.age,
          job: item.job,
          city: item.city,
          country: item.country,
          hobbies: item.hobbies,
          phone: phoneNumberFixer(item.phone),
          gender: item.gender,
          category: item.category,
          sub_category: item.sub_category,
          profile_complete: item.profile_complete,
          "insta.full_name": item.insta?.full_name,
          "insta.username": item.insta?.username,
          "insta.post_number": item.insta?.post_number,
          "insta.followers": item.insta?.followers,
          "insta.following": item.insta?.following,
          "insta.average_like": numFixer(item.insta?.average_like),
          "insta.average_reel_view": numFixer(item.insta?.average_reel_view),
          "insta.face_count": item.insta?.face_count,
          "insta.engagement_rate": numFixer(item.insta?.engagement_rate),
          "tiktok.username": item.tiktok?.username,
          "tiktok.followers": numFixer(item.tiktok?.followers),
          "tiktok.following": numFixer(item.tiktok?.following),
          "tiktok.videos": item.tiktok?.videos,
          "tiktok.tiktok_average_like": numFixer(item.tiktok?.tiktok_average_like),
          "tiktok.tiktok_engagement_rate": numFixer(item.tiktok?.tiktok_engagement_rate),
          "tiktok.hearts": numFixer(item.tiktok?.hearts),
          "insta.keywords": item.insta?.keywords,
          _id: item._id,
          verification: item.verification,
          ...item,
        }
      })
      return { data, totalPages }
    }
  } catch (error: any) {
    throw new Error(error)
  }
}

const GetAllUsers = () => {
  const userFilters = useSelector(selectUserFilters)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setPageTitle("Range Search Table"))
    const initialFilters = userFilters // assuming this comes from `useSelector(selectUserFilters)`
    if (
      Object.keys(initialFilters).length > 0 &&
      JSON.stringify(initialFilters) !== JSON.stringify(defaultState)
    ) {
      setFilterss(initialFilters)
    }
  }, [dispatch])

  const [userData, setUserData] = useState([] as WaitingApprovalUserData[])
  const [page, setPage] = useState(1)
  const PAGE_SIZES = [10, 20, 30, 50, 100]
  const [pageSize, setPageSize] = useState(PAGE_SIZES[2])
  const [totalPages, setTotalPages] = useState(0)
  const [initialRecords, setInitialRecords] = useState(sortBy(userData, "id"))
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({
    columnAccessor: "id",
    direction: "asc",
  })
  const [error, setError] = useState<string | null>(null)
  const [keywords, setKeywords] = useState("")
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [autofillCountries, setAutofillCountries] = useState<string[]>([])
  const [country, setCountry] = useState("")
  const [autofillCities, setAutofillCities] = useState<string[]>([])
  const [city, setCity] = useState("")
  const [autofillJobs, setAutofillJobs] = useState<string[]>([])
  const [job, setJob] = useState("")
  const [isHobbyDropdownOpen, setIsHobbyDropdownOpen] = useState(false)
  const [isLoginDropdownOpen, setIsLoginDropdownOpen] = useState(false)
  const [selectingHobbies, setSelectingHobbies] = useState<string[]>([])
  const [sortByColumn, setSortByColumn] = useState("")
  const [sortOrder, setSortOrder] = useState(false)
  const hobbies = HobbyData.hobbies
  const cities = CityData.cities
  const countries = CountryData.countries
  const jobs = JobData.jobs
  const [exportCSV, setExportCSV] = useState("")
  const [category, setCategory] = useState("")
  const [selectedRecords, setSelectedRecords] = useState<any>([])
  const [isEditCategoryModalOpen, setIsEditCategoryModalOpen] = useState({
    modal: false,
    _id: "",
  })
  const [subCategory, setSubCategory] = useState([""])
  const [isEditSubCategoryModalOpen, setIsEditSubCategoryModalOpen] = useState({
    modal: false,
    _id: "",
  })
  const [selectedUser, setSelectedUser] = useState(null)
  const [AISearch, setAISearch] = useState("")
  const [isOpenDetailModal, setIsOpenDetailModal] = useState<boolean>(false)

  const defaultState: Filters = {
    age: { min: "", max: "" },
    followers: { min: "", max: "" },
    average_like: { min: "", max: "" },
    average_reel_view: { min: "", max: "" },
    engagement_rate: { min: "", max: "" },
    tiktok_followers: { min: "", max: "" },
    tiktok_average_like: { min: "", max: "" },
    tiktok_engagement_rate: { min: "", max: "" },
    country: { value: "" },
    city: { value: "" },
    job: { value: "" },
    keywords: [],
    frequency: 0,
    hobbies: [],
    gender: "",
    verification: "",
    platform: "",
    is_spam: "",
    unvisible: "",
    warnings: "",
    last_login: "",
  }
  const [filters, setFilterss] = useState<Filters>(defaultState)

  const setFilter = (
    key: keyof Filters,
    type: FilterType,
    value: string | string[] | ("male" | "female" | "" | "true" | "false") | number,
  ) => {
    if (key === "keywords") {
      setFilterss((prev) => ({ ...prev, [key]: value as string[] }))
      dispatch(setUserFilters({ ...filters }))
    } else if (key === "hobbies") {
      const newHobbies = Array.isArray(value) ? value : [value]
      setFilterss((prev) => ({ ...prev, [key]: newHobbies } as Filters))
      dispatch(setUserFilters({ ...filters, [key]: newHobbies }))
    } else if (key === "frequency") {
      setFilterss((prev) => ({ ...prev, [key]: value as number }))
      dispatch(setUserFilters({ ...filters }))
    } else if (key === "verification") {
      setFilterss((prev) => ({ ...prev, [key]: value as "true" | "false" | "" }))
      dispatch(setUserFilters({ ...filters }))
    } else if (key === "gender") {
      setFilterss((prev) => ({ ...prev, [key]: value as "male" | "female" | "" }))
      dispatch(setUserFilters({ ...filters }))
    } else if (key === "city") {
      setFilterss((prev) => ({ ...prev, [key]: { ...prev[key], [type]: value as string } }))
      dispatch(setUserFilters({ ...filters }))
    } else if (key === "country") {
      setFilterss((prev) => ({ ...prev, [key]: { ...prev[key], [type]: value as string } }))
      dispatch(setUserFilters({ ...filters }))
    } else if (key === "job") {
      setFilterss((prev) => ({ ...prev, [key]: { ...prev[key], [type]: value as string } }))
      dispatch(setUserFilters({ ...filters }))
    } else if (key === "warnings") {
      setFilterss((prev) => ({ ...prev, [key]: value as "true" | "false" | "" }))
      dispatch(setUserFilters({ ...filters }))
    } else if (key === "platform") {
      setFilterss((prev) => ({ ...prev, [key]: value as string }))
      dispatch(setUserFilters({ ...filters }))
    } else if (key === "is_spam") {
      setFilterss((prev) => ({ ...prev, [key]: value as "true" | "false" | "" }))
      dispatch(setUserFilters({ ...filters }))
    } else if (key === "unvisible") {
      setFilterss((prev) => ({ ...prev, [key]: value as "true" | "false" | "" }))
      dispatch(setUserFilters({ ...filters }))
    } else if (key === "last_login") {
      setFilterss((prev) => ({ ...prev, [key]: value as string }))
      dispatch(setUserFilters({ ...filters }))
    } else {
      setFilterss((prev) => ({ ...prev, [key]: { ...prev[key], [type]: value as string } }))
      dispatch(setUserFilters({ ...filters }))
    }
  }

  useEffect(() => {
    dispatch(setUserFilters(filters))
  }, [filters, dispatch])

  const handleFetchData = async () => {
    setLoading(true)
    if (AISearch.length > 0) {
      handleAISearch()
    } else {
      const flattenFilters = Object.entries(filters).reduce((acc, [key, filter]) => {
        if (key === "keywords" || key === "hobbies") {
          console.log()
        } else if (key === "gender" && typeof filter === "string") {
          acc[key] = filter
        } else if (key === "verification" && typeof filter === "string") {
          acc[key] = filter
        } else if (key === "warnings" && typeof filter === "string") {
          acc[key] = filter
        } else if (key === "platform" && typeof filter === "string") {
          acc[key] = filter
        } else if (key === "is_spam" && typeof filter === "string") {
          acc[key] = filter
        } else if (key === "unvisible" && typeof filter === "string") {
          acc[key] = filter
        } else if (key === "last_login" && typeof filter === "string") {
          acc[key] = filter
        } else if (key === "frequency" && typeof filter === "string") {
          acc[key] = filter
        } else if (key === "country") {
          acc[key] = (filter as CountryFilterValue).value
        } else if (key === "city") {
          acc[key] = (filter as CityFilterValue).value
        } else if (key === "job") {
          acc[key] = (filter as JobFilterValue).value
        } else {
          const { min, max } = filter as FilterValue
          if (min) {
            acc[`min_${key}`] = min
          }
          if (max) {
            acc[`max_${key}`] = max
          }
        }

        return acc
      }, {} as { [key: string]: string })

      const params = new URLSearchParams(flattenFilters)
      const keywords = (filters.keywords as string[]).map(
        (keyword) => keyword.charAt(0).toUpperCase() + keyword.slice(1),
      )
      keywords.forEach((keywords) => {
        params.append("keywords", keywords)
      })

      const hobbies = (filters.hobbies as string[]).map((hobby) => hobby.charAt(0) + hobby.slice(1))
      hobbies.forEach((hobbies) => {
        params.append("hobbies", hobbies)
      })
      try {
        const data: any = await fetchData(
          page,
          pageSize,
          params,
          sortByColumn,
          sortOrder,
          exportCSV,
        )
        if (data !== undefined) {
          setUserData(data.data)
          setInitialRecords(data.data)
          setTotalPages(data.totalPages)
        } else {
          setError("No data found")
        }
        setLoading(false)
      } catch (error) {
        setError("No data found")
      }
    }
  }

  useEffect(() => {
    handleFetchData()
  }, [page, pageSize, sortByColumn, sortOrder, exportCSV])

  const handleSorting = (sortyBy: string) => {
    setSortByColumn(sortyBy)
    setSortOrder((prevOrder) => !prevOrder)
  }

  const filterKeys: (keyof Filters)[] = [
    "age",
    "followers",
    "average_like",
    "average_reel_view",
    "engagement_rate",
    "tiktok_followers",
    "tiktok_average_like",
    "tiktok_engagement_rate",
    "country",
    "city",
    "job",
    "keywords",
    "frequency",
    "hobbies",
    "gender",
    "verification",
    "warnings",
    "platform",
    "is_spam",
    "unvisible",
    "last_login",
  ]

  const handleKeywordInputChange = (e: any) => {
    const inputKeywords = e.target.value
      .split(" ")
      .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ")
    setKeywords(inputKeywords)

    if (inputKeywords.length === 0) {
      setIsDropdownOpen(false)
    } else {
      setIsDropdownOpen(true)
    }
  }

  const autoCompleteKeywords = keywords.split(",").map((keyword) => keyword.trim())

  const lastKeyword = autoCompleteKeywords[autoCompleteKeywords.length - 1]

  const uniqueKeywords = [...new Set(KeywordData.keywords)]
  const filteredKeywords = uniqueKeywords
    .filter((keyword: string) => keyword.toLowerCase().startsWith(lastKeyword.toLowerCase()))
    .slice(0, 4)

  const autoCompleteKeyword: string[] = autoCompleteKeywords.length === 0 ? [] : filteredKeywords

  const handleCheckboxChange = (hobby: any) => {
    let updatedHobbies
    if (selectingHobbies.includes(hobby)) {
      updatedHobbies = selectingHobbies.filter((item) => item !== hobby)
    } else {
      updatedHobbies = [...selectingHobbies, hobby]
    }
    setSelectingHobbies(updatedHobbies)
    setFilter("hobbies", "value", updatedHobbies)
  }

  useEffect(() => {
    const handleClick = () => {
      setIsDropdownOpen(false)
    }
    document.addEventListener("click", handleClick)
    return () => {
      document.removeEventListener("click", handleClick)
    }
  }, [])

  const formatKey = (key: string) => {
    switch (key) {
      case "age":
        return "Yaş"
      case "followers":
        return "IG Takipçi"
      case "average_like":
        return "IG Ort Beğeni"
      case "average_reel_view":
        return "IG Ort Reel İzlenme"
      case "engagement_rate":
        return "IG Eng. Oranı"
      case "tiktok_followers":
        return "TT Takipçi"
      case "tiktok_average_like":
        return "TT Ort Beğeni"
      case "tiktok_engagement_rate":
        return "TT Eng. Oranı"
      default:
        return key
    }
  }

  const renderUserId = (record: any, index: any) => {
    const itemsPerPage = page * pageSize
    const recordIndex = itemsPerPage + index
    const brandId = recordIndex - pageSize + 1
    return <div>{brandId}</div>
  }

  const handleInputChangeCountry = (e: any) => {
    const value = e.target.value
    setFilter("country", "value", value)
    setCountry(value)

    if (value.length > 0) {
      const filteredCountries = countries
        .filter((country) => country.name.toLowerCase().startsWith(value.toLowerCase()))
        .map((country) => country.name)
      setAutofillCountries(filteredCountries)
    } else {
      setAutofillCountries([])
    }
  }

  const handleCountrySuggestionClick = (key: any, selectedCountry: any) => {
    setFilter(key, "value", selectedCountry)
    setCountry(selectedCountry)
    setAutofillCountries([])
  }

  const handleInputChangeCity = (e: any) => {
    const value = e.target.value
    setFilter("city", "value", value)
    setCity(value)

    if (value.length > 0) {
      const filteredCities = cities
        .filter((city) => city.name.toLowerCase().startsWith(value.toLowerCase()))
        .map((city) => city.name)
      setAutofillCities(filteredCities)
    } else {
      setAutofillCities([])
    }
  }
  const handleCitySuggestionClick = (key: any, selectedCity: any) => {
    setFilter(key, "value", selectedCity)
    setCity(selectedCity)
    setAutofillCities([])
  }

  const handleInputChangeJob = (e: any) => {
    const value = e.target.value
    setFilter("job", "value", value)
    setJob(value)

    if (value.length > 0) {
      const filteredJobs = jobs
        .filter((job) => job.name.toLowerCase().startsWith(value.toLowerCase()))
        .map((job) => job.name)
      setAutofillJobs(filteredJobs)
    } else {
      setAutofillJobs([])
    }
  }
  const handleJobSuggestionClick = (key: any, selectedJob: any) => {
    setFilter(key, "value", selectedJob)
    setJob(selectedJob)
    setAutofillJobs([])
  }

  const removeVerification = async (id: any) => {
    try {
      const response = await TAremoveVerification(id)
      if (response) {
        setInitialRecords((prevUsers) =>
          prevUsers.map((user) => (user._id === id ? { ...user, verification: false } : user)),
        )
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const addVerification = async (id: any) => {
    try {
      const response = await TAverifyUser(id, "true")
      if (response) {
        setInitialRecords((prevUsers) =>
          prevUsers.map((user) => (user._id === id ? { ...user, verification: true } : user)),
        )
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const banUser = async (id: any) => {
    try {
      const response = await TAbanUser(id)
      if (response) {
        setInitialRecords((prevUsers) => prevUsers.filter((user) => user._id !== id))
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const handleEditCategory = async (id: any, category: string) => {
    try {
      const response = await TAupdateCategory(id, category)
      if (response) {
        setInitialRecords((prevUsers) =>
          prevUsers.map((user) => (user._id === id ? { ...user, category } : user)),
        )
        setCategory("")
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const handleEditSubCategory = async (id: any, subCategory: string[]) => {
    try {
      const response = await TAupdateSubCategory(id, subCategory)
      if (response) {
        setInitialRecords((prevUsers) =>
          prevUsers.map((user) =>
            user._id === id ? { ...user, sub_category: subCategory } : user,
          ),
        )
        setSubCategory([""])
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const handleSelectAll = (isChecked) => {
    if (isChecked) {
      const allIds = initialRecords.map((record) => record._id)
      setSelectedRecords(allIds)
    } else {
      setSelectedRecords([])
    }
  }

  const handleSelectRow = (id) => {
    setSelectedRecords((prevSelected: any) =>
      prevSelected.includes(id)
        ? prevSelected.filter((selectedId) => selectedId !== id)
        : [...prevSelected, id],
    )
  }

  const isAllSelected = selectedRecords.length === initialRecords.length
  const isIndeterminate =
    selectedRecords.length > 0 && selectedRecords.length < initialRecords.length

  const handleBulkDelete = async () => {
    try {
      const response = await TAbanManyUsers(selectedRecords)
      if (response) {
        const updatedRecords = initialRecords.filter(
          (record) => !selectedRecords.includes(record._id),
        )
        setInitialRecords(updatedRecords)
        setSelectedRecords([])
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const handleAISearch = async () => {
    setLoading(true)
    try {
      const response = await TAChatGptSearchUser(AISearch)
      if (response !== undefined) {
        await handleUserWithQuery(response)
      }
      setLoading(false)
    } catch (error) {
      setError("No data found")
    }
  }

  const handleUserWithQuery = async (queryRes: any) => {
    setLoading(true)
    const query = { ...queryRes }
    try {
      const response2 = await TAAISearchUser(query, page, pageSize, sortByColumn, sortOrder)
      if (response2 !== undefined) {
        if (response2 && Array.isArray(response2.users)) {
          const totalPages = response2.totalPages
          const data = response2.users.map((item: any, index: any) => {
            return {
              id: index + 1,
              name: item.name,
              email: item.email,
              age: item.age,
              job: item.job,
              city: item.city,
              country: item.country,
              hobbies: item.hobbies,
              phone: phoneNumberFixer(item.phone),
              gender: item.gender,
              category: item.category,
              sub_category: item.sub_category,
              profile_complete: item.profile_complete,
              "insta.full_name": item.insta?.full_name,
              "insta.username": item.insta?.username,
              "insta.post_number": item.insta?.post_number,
              "insta.followers": item.insta?.followers,
              "insta.following": item.insta?.following,
              "insta.average_like": numFixer(item.insta?.average_like),
              "insta.average_reel_view": numFixer(item.insta?.average_reel_view),
              "insta.face_count": item.insta?.face_count,
              "insta.engagement_rate": numFixer(item.insta?.engagement_rate),
              "tiktok.username": item.tiktok?.username,
              "tiktok.followers": numFixer(item.tiktok?.followers),
              "tiktok.following": numFixer(item.tiktok?.following),
              "tiktok.videos": item.tiktok?.videos,
              "tiktok.tiktok_average_like": numFixer(item.tiktok?.tiktok_average_like),
              "tiktok.tiktok_engagement_rate": numFixer(item.tiktok?.tiktok_engagement_rate),
              "tiktok.hearts": numFixer(item.tiktok?.hearts),
              "insta.keywords": item.insta?.keywords,
              _id: item._id,
              verification: item.verification,
              ...item,
            }
          })
          setUserData(data)
          setInitialRecords(data)
          setTotalPages(totalPages)
        }
      } else {
        setError("No data found")
      }
    } catch (error) {
      setError("No data found")
    }
  }

  return (
    <div className="panel">
      <div className="flex md:items-center md:flex-row flex-col">
        <div className="flex flex-col justify-center text-center"></div>
        <div className="md:flex md:flex-row w-full">
          {filterKeys.map((key) => {
            if (key === "gender") {
              return (
                <div key={key} className="md:flex md:flex-col mr-2 ml-2">
                  <h2 className="text-sm font-bold mb-2">Cinsiyet</h2>
                  <label>
                    <input
                      type="radio"
                      value="male"
                      checked={filters.gender === "male"}
                      onChange={(e) => {
                        setFilter(key, "value", e.target.value)
                      }}
                      className="form-radio text-pink-600 mr-2"
                    />
                    Erkek
                  </label>
                  <label>
                    <input
                      type="radio"
                      value="female"
                      checked={filters.gender === "female"}
                      onChange={(e) => {
                        setFilter(key, "value", e.target.value)
                      }}
                      className="form-radio text-pink-600 mr-2"
                    />
                    Kadın
                  </label>
                  <label>
                    <input
                      type="radio"
                      value=""
                      checked={filters.gender === ""}
                      onChange={(e) => {
                        setFilter(key, "value", e.target.value)
                      }}
                      className="form-radio text-pink-600 mr-2"
                    />
                    Tümü
                  </label>
                </div>
              )
            } else if (key === "verification") {
              return (
                <div key={key} className="md:flex md:flex-col mr-2 ml-2">
                  <h2 className="text-sm font-bold mb-2">Onay Durumu</h2>
                  <label>
                    <input
                      type="radio"
                      value="true"
                      checked={filters.verification === "true"}
                      onChange={(e) => {
                        setFilter(key, "value", e.target.value)
                      }}
                      className="form-radio text-pink-600 mr-2"
                    />
                    Onaylı
                  </label>
                  <label>
                    <input
                      type="radio"
                      value="false"
                      checked={filters.verification === "false"}
                      onChange={(e) => {
                        setFilter(key, "value", e.target.value)
                      }}
                      className="form-radio text-pink-600 mr-2"
                    />
                    Onaysız
                  </label>
                  <label>
                    <input
                      type="radio"
                      value=""
                      checked={filters.verification === ""}
                      onChange={(e) => {
                        setFilter(key, "value", e.target.value)
                      }}
                      className="form-radio text-pink-600 mr-2"
                    />
                    Tümü
                  </label>
                </div>
              )
            } else if (key === "platform") {
              return (
                <div key={key} className="md:flex md:flex-col mr-2 ml-2">
                  <h2 className="text-sm font-bold mb-2">Platform</h2>
                  <label>
                    <input
                      type="radio"
                      value="insta"
                      checked={filters.platform === "insta"}
                      onChange={(e) => {
                        setFilter(key, "value", e.target.value)
                      }}
                      className="form-radio text-pink-600 mr-2"
                    />
                    Instagram
                  </label>
                  <label>
                    <input
                      type="radio"
                      value="tiktok"
                      checked={filters.platform === "tiktok"}
                      onChange={(e) => {
                        setFilter(key, "value", e.target.value)
                      }}
                      className="form-radio text-pink-600 mr-2"
                    />
                    TikTok
                  </label>
                  <label>
                    <input
                      type="radio"
                      value=""
                      checked={filters.platform === ""}
                      onChange={(e) => {
                        setFilter(key, "value", e.target.value)
                      }}
                      className="form-radio text-pink-600 mr-2"
                    />
                    Tümü
                  </label>
                </div>
              )
            } else if (key === "is_spam") {
              return (
                <div key={key} className="md:flex md:flex-col mr-2 ml-2">
                  <h2 className="text-sm font-bold mb-2">Spam Durumu</h2>
                  <label>
                    <input
                      type="radio"
                      value="true"
                      checked={filters.is_spam === "true"}
                      onChange={(e) => {
                        setFilter(key, "value", e.target.value)
                      }}
                      className="form-radio text-pink-600 mr-2"
                    />
                    Spam
                  </label>
                  <label>
                    <input
                      type="radio"
                      value="false"
                      checked={filters.is_spam === "false"}
                      onChange={(e) => {
                        setFilter(key, "value", e.target.value)
                      }}
                      className="form-radio text-pink-600 mr-2"
                    />
                    Spam Değil
                  </label>
                  <label>
                    <input
                      type="radio"
                      value=""
                      checked={filters.is_spam === ""}
                      onChange={(e) => {
                        setFilter(key, "value", e.target.value)
                      }}
                      className="form-radio text-pink-600 mr-2"
                    />
                    Tümü
                  </label>
                </div>
              )
            } else if (key === "unvisible") {
              return (
                <div key={key} className="md:flex md:flex-col mr-2 ml-2">
                  <h2 className="text-sm font-bold mb-2">Görünürlük Durumu</h2>
                  <label>
                    <input
                      type="radio"
                      value="true"
                      checked={filters.unvisible === "true"}
                      onChange={(e) => {
                        setFilter(key, "value", e.target.value)
                      }}
                      className="form-radio text-pink-600 mr-2"
                    />
                    Görünür
                  </label>
                  <label>
                    <input
                      type="radio"
                      value="false"
                      checked={filters.unvisible === "false"}
                      onChange={(e) => {
                        setFilter(key, "value", e.target.value)
                      }}
                      className="form-radio text-pink-600 mr-2"
                    />
                    Görünmez
                  </label>
                  <label>
                    <input
                      type="radio"
                      value=""
                      checked={filters.unvisible === ""}
                      onChange={(e) => {
                        setFilter(key, "value", e.target.value)
                      }}
                      className="form-radio text-pink-600 mr-2"
                    />
                    Tümü
                  </label>
                </div>
              )
            } else if (
              key !== "country" &&
              key !== "keywords" &&
              key !== "city" &&
              key !== "hobbies" &&
              key !== "job" &&
              key !== "frequency" &&
              key !== "warnings" &&
              key !== "last_login"
            ) {
              return (
                <div
                  key={key}
                  className="md:flex md:flex-col flex-1 mr-2 justify-center items-center"
                >
                  <h2 className="text-sm font-bold mb-2">{formatKey(key)}</h2>
                  <input
                    type="text"
                    value={(filters[key] as FilterValue)?.min}
                    onChange={(e) => {
                      setFilter(key, "min", e.target.value)
                    }}
                    className="form-input w-full mb-2"
                    placeholder={`min. ${key}`}
                  />

                  <input
                    type="text"
                    value={(filters[key] as FilterValue)?.max}
                    onChange={(e) => {
                      setFilter(key, "max", e.target.value)
                    }}
                    className="form-input w-full"
                    placeholder={`max. ${key}`}
                  />
                </div>
              )
            }
          })}
        </div>
      </div>
      <div className="flex w-full justify-between flex-end">
        <div className="flex flex-row w-2/3 items-center">
          <div className="md:flex md:flex-row">
            {filterKeys.map((key) => {
              if (key === "country") {
                return (
                  <div key={key}>
                    <div className="md:flex md:flex-col flex-1 mb-4 justify-center items-center">
                      <h2 className="text-sm font-bold mb-1 mt-3">Ülke</h2>
                      <input
                        type="text"
                        value={filters[key].value}
                        onChange={handleInputChangeCountry}
                        className="form-input"
                        placeholder="Ülke ismi"
                      />
                    </div>
                    {autofillCountries.length > 0 && country.length > 0 && (
                      <ul
                        className="suggestion-list"
                        style={{ position: "absolute", zIndex: 9999 }}
                      >
                        {[...new Set(autofillCountries)]
                          .slice(0, 5)
                          .map((autofillCountry, index) => (
                            <li
                              key={index}
                              className="bg-white p-2 text-black cursor-pointer hover:bg-gray-200"
                              onClick={() =>
                                handleCountrySuggestionClick("country", autofillCountry)
                              }
                            >
                              {autofillCountry}
                            </li>
                          ))}
                      </ul>
                    )}
                  </div>
                )
              } else if (key === "city") {
                return (
                  <div key={key}>
                    <div className="md:flex md:flex-col flex-1 mb-4 ml-5 justify-center items-center">
                      <h2 className="text-sm font-bold mb-1 mt-3">Şehir</h2>
                      <input
                        type="text"
                        value={filters[key].value}
                        onChange={handleInputChangeCity}
                        className="form-input"
                        placeholder="Şehir ismi"
                      />
                    </div>
                    {autofillCities.length > 0 && city.length > 0 && (
                      <ul
                        className="suggestion-list"
                        style={{ position: "absolute", zIndex: 9999 }}
                      >
                        {[...new Set(autofillCities)].slice(0, 5).map((autofillCity, index) => (
                          <li
                            key={index}
                            className="bg-white ml-4 p-2 text-black cursor-pointer hover:bg-gray-200"
                            onClick={() => handleCitySuggestionClick("city", autofillCity)}
                          >
                            {autofillCity}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                )
              } else if (key === "job") {
                return (
                  <div key={key}>
                    <div className="md:flex md:flex-col flex-1 mb-4 ml-5 justify-center items-center">
                      <h2 className="text-sm font-bold mb-1 mt-3">Meslek</h2>
                      <input
                        type="text"
                        value={filters[key].value}
                        onChange={handleInputChangeJob}
                        className="form-input"
                        placeholder={"Meslek ismi"}
                      />
                    </div>
                    {autofillJobs.length > 0 && job.length > 0 && (
                      <ul
                        className="suggestion-list"
                        style={{ position: "absolute", zIndex: 9999 }}
                      >
                        {[...new Set(autofillJobs)].slice(0, 5).map((autofillJob, index) => (
                          <li
                            key={index}
                            className="bg-white p-2 ml-6 text-black cursor-pointer hover:bg-gray-200"
                            onClick={() => handleJobSuggestionClick("job", autofillJob)}
                          >
                            {autofillJob}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                )
              } else if (key === "keywords") {
                return (
                  <div key={key}>
                    <div className="md:flex md:flex-col flex-1 mb-4 ml-5 justify-center items-center">
                      <h2 className="text-sm font-bold mb-1 mt-3">Nesneler</h2>
                      <input
                        type="text"
                        value={filters[key].join(",")}
                        onChange={(e) => {
                          const keywords = e.target.value.split(",").map((word) => {
                            const trimmedWord = word.trim()
                            return (
                              trimmedWord.charAt(0).toUpperCase() +
                              trimmedWord.slice(1).toLowerCase()
                            )
                          })
                          setFilter(key, "value", keywords)
                          handleKeywordInputChange(e)
                        }}
                        className="form-input"
                        placeholder={"Nesne1, Nesne2, ..."}
                      />
                      {isDropdownOpen && keywords.length > 0 && (
                        <div>
                          <ul
                            className="suggestion-list"
                            style={{ position: "absolute", zIndex: 9999 }}
                          >
                            {autoCompleteKeyword.map((keyword, index) => (
                              <li
                                key={index}
                                className="bg-white p-2 text-black cursor-pointer hover:bg-gray-200"
                                onClick={() => {
                                  const currentInput = filters[key].join(", ")

                                  if (currentInput.includes(",")) {
                                    const parts = currentInput.split(",")
                                    parts[parts.length - 1] = keyword
                                    setFilter(
                                      key,
                                      "value",
                                      parts.map((part) => part.trim()),
                                    )
                                  } else {
                                    setFilter(key, "value", [keyword])
                                  }
                                  setIsDropdownOpen(false)
                                }}
                              >
                                {keyword}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                )
              } else if (key === "frequency") {
                return (
                  <div key={key}>
                    <div className="md:flex md:flex-col flex-1 mb-4 ml-5 justify-center items-center">
                      <h2 className="text-sm font-bold mb-1 mt-3">Sıklık</h2>
                      <input
                        type="number"
                        value={filters[key]}
                        onChange={(e) => {
                          setFilter(key, "value", e.target.value)
                        }}
                        className="form-input"
                        placeholder={"3"}
                      />
                    </div>
                  </div>
                )
              } else if (key === "hobbies") {
                return (
                  <div key={key}>
                    <div className="md:flex md:flex-col flex-1 mb-4 ml-5 mt-3 justify-center items-center">
                      <button
                        className=" inline-flex items-center justify-center mt-5 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 px-3"
                        onClick={() => setIsHobbyDropdownOpen(!isHobbyDropdownOpen)}
                      >
                        Hobileri Seç
                      </button>
                      {isHobbyDropdownOpen && (
                        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                          <div className="bg-white p-4 rounded shadow-lg w-full max-w-xs mx-auto">
                            <h2 className="text-lg font-bold mb-4">Hobileri Seç</h2>
                            <div className="max-h-64 overflow-y-auto">
                              {hobbies.map((hobby, index) => (
                                <div key={index} className="mb-2">
                                  <label className="flex items-center">
                                    <input
                                      type="checkbox"
                                      name="hobbies"
                                      value={hobby}
                                      checked={selectingHobbies.includes(hobby)}
                                      onChange={() => handleCheckboxChange(hobby)}
                                      className="mr-2"
                                    />
                                    {hobby}
                                  </label>
                                </div>
                              ))}
                            </div>
                            <button
                              className="mt-4 bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700"
                              onClick={() => setIsHobbyDropdownOpen(false)}
                            >
                              Kaydet
                            </button>
                            <button
                              className="mt-4 ml-12 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
                              onClick={() => setIsHobbyDropdownOpen(false)}
                            >
                              Kapat
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )
              } else if (key === "warnings") {
                return (
                  <div
                    key={key}
                    className="md:flex md:flex-col flex-1 mb-4 ml-5 mt-3 justify-center items-center"
                  >
                    <h2 className="text-sm font-bold mb-2">Uyarılar</h2>
                    <input
                      type="checkbox"
                      checked={filters.warnings === "true"}
                      onChange={(e) => {
                        setFilter(key, "value", e.target.checked ? "true" : "")
                      }}
                      className="form-checkbox text-pink-600"
                    />
                  </div>
                )
              } else if (key === "last_login") {
                return (
                  <div key={key}>
                    <div className="md:flex md:flex-col flex-1 mb-4 ml-5 mt-3 justify-center items-center">
                      <button
                        className=" inline-flex items-center justify-center mt-5 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 px-3"
                        onClick={() => setIsLoginDropdownOpen(!isLoginDropdownOpen)}
                      >
                        Son Giriş Tarihi
                      </button>
                      {isLoginDropdownOpen && (
                        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                          <div className="bg-white p-4 rounded shadow-lg w-full max-w-xs mx-auto">
                            Son Giriş Tarihi
                            <h2 className="text-lg font-bold mb-4"></h2>
                            <div className="max-h-64 overflow-y-auto">
                              {["1 Ay", "3 Ay", "6 Ay", "1 Yıl", "any"].map((label, index) => {
                                const monthsAgo = [1, 3, 6, 12]
                                const value = new Date(
                                  new Date().setMonth(new Date().getMonth() - monthsAgo[index]),
                                ).toLocaleDateString("en-CA")

                                return (
                                  <label key={label}>
                                    <input
                                      type="radio"
                                      value={value}
                                      checked={filters.last_login === value}
                                      onChange={(e) => {
                                        setFilter(key, "value", e.target.value)
                                      }}
                                      className="form-radio text-pink-600 mr-2"
                                    />
                                    {label}
                                  </label>
                                )
                              })}
                            </div>
                            <button
                              className="mt-4 bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700"
                              onClick={() => setIsLoginDropdownOpen(false)}
                            >
                              Kaydet
                            </button>
                            <button
                              className="mt-4 ml-12 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
                              onClick={() => setIsLoginDropdownOpen(false)}
                            >
                              Kapat
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )
              }
            })}
          </div>
        </div>

        <div className="flex flex-row justify-end text-center w-1/3 mb-4 mr-2">
          <button
            className=" inline-flex items-center justify-center ml-2 px-2 py-2 mt-6 mb-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 "
            onClick={() => handleFetchData()}
          >
            Kullanıcı Ara
          </button>
          <button
            className=" inline-flex items-center justify-center ml-2 px-2 py-2 mt-6 mb-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 "
            onClick={() => setExportCSV("true")}
          >
            10 Sayfa Arama Yap
          </button>
          {/* <DownloadPdfButton
            className=" inline-flex items-center justify-center px-2 py-2 mt-6 mb-2 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 "
            userData={initialRecords}
          /> */}
          <DownloadCSVButton
            className=" inline-flex items-center justify-center ml-2 px-2 py-2 mt-6 mb-2 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 "
            userData={initialRecords}
          />
        </div>
      </div>
      <div className="flex items-center">
        <div
          className="cursor-pointer inline-flex items-center justify-center p-2 mb-4 border border-transparent text-sm font-medium rounded-md text-white bg-gray-400 hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 "
          onClick={() => handleBulkDelete()}
        >
          <span>Seçilenleri Banla</span>
          <FontAwesomeIcon className="ml-2" icon={faBan} style={{ color: "red" }} />
        </div>
        <div className="flex w-1/3 ml-4 items-center border border-gray-300 rounded-xl mb-4 p-2">
          <FontAwesomeIcon icon={faSearch} className="text-gray-400 mr-2" />
          <input
            type="text"
            placeholder="AI araması yap"
            className="w-full h-8 border-none bg-gray-100 focus:outline-none rounded-xl pl-3"
            aria-label="AI search"
            value={AISearch}
            onChange={(e) => setAISearch(e.target.value)}
          />
        </div>
      </div>
      {isEditCategoryModalOpen.modal && (
        <EditCategoryModal
          category={category}
          setCategory={setCategory}
          onClose={() => {
            setIsEditCategoryModalOpen({ modal: false, _id: "" })
            setCategory("")
          }}
          handleSave={() => handleEditCategory(isEditCategoryModalOpen._id, category)}
        />
      )}
      {isEditSubCategoryModalOpen.modal && (
        <EditSubCategoryModal
          subCategory={subCategory}
          setSubCategory={setSubCategory}
          onClose={() => {
            setIsEditSubCategoryModalOpen({ modal: false, _id: "" })
            setSubCategory([""])
          }}
          handleSave={() => handleEditSubCategory(isEditSubCategoryModalOpen._id, subCategory)}
        />
      )}
      <ProfileDetailModal
        data={selectedUser}
        isOpen={isOpenDetailModal}
        onClose={() => {
          setIsOpenDetailModal(false)
        }}
      />
      <div className="datatables">
        {loading ? (
          <div className="flex items-center justify-center h-40">
            <div className="animate-spin rounded-full h-10 w-10 border-t-4 border-pink-600"></div>
          </div>
        ) : (
          <div>
            <DataTable
              highlightOnHover
              className="whitespace-nowrap table-hover"
              records={initialRecords}
              columns={[
                {
                  accessor: "select",
                  title: (
                    <input
                      type="checkbox"
                      checked={isAllSelected}
                      ref={(input) => {
                        if (input) {
                          input.indeterminate = isIndeterminate
                        }
                      }}
                      onChange={(e) => handleSelectAll(e.target.checked)}
                    />
                  ),
                  sortable: false,
                  render: ({ _id }) => (
                    <input
                      type="checkbox"
                      checked={selectedRecords.includes(_id)}
                      onChange={() => handleSelectRow(_id)}
                    />
                  ),
                },
                {
                  accessor: "id",
                  title: "Id",
                  sortable: true,
                  render: (record) => (
                    <div
                      onClick={() => {
                        setSelectedUser(record)
                        setIsOpenDetailModal(true)
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {renderUserId(record, record.id - 1)}
                    </div>
                  ),
                },
                {
                  accessor: "verification",
                  title: "Onay Durumu",
                  sortable: true,
                  render: ({ verification, _id }: any) => (
                    <div className="text-center items-center">
                      {verification ? (
                        <FontAwesomeIcon
                          icon={faStar}
                          style={{ color: "#ffba00", cursor: "pointer" }}
                          onClick={() => removeVerification(_id)}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faTimes}
                          style={{ color: "red", cursor: "pointer" }}
                          onClick={() => addVerification(_id)}
                        />
                      )}
                    </div>
                  ),
                },
                {
                  accessor: "details",
                  title: "Detaylar",
                  sortable: true,
                  render: ({ _id }: any) => (
                    <a href={`/user/find/${_id}`} target="_blank" rel="noopener noreferrer">
                      <div className="text-center items-center">
                        <FontAwesomeIcon icon={faEye} style={{ color: "#005eff" }} />
                      </div>
                    </a>
                  ),
                },
                {
                  accessor: "ban_user",
                  title: "Kullanıcıyı Banla",
                  sortable: true,
                  render: ({ _id }) => (
                    <div className="text-center items-center">
                      <FontAwesomeIcon
                        icon={faBan}
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={() => banUser(_id)}
                      />
                    </div>
                  ),
                },
                {
                  accessor: "firstName",
                  title: "İsim",
                  sortable: true,
                  render: (record) => (
                    <div
                      onClick={() => {
                        setSelectedUser(record)
                        setIsOpenDetailModal(true)
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {record.name}
                    </div>
                  ),
                },
                {
                  accessor: "phone",
                  title: "Telefon",
                  sortable: true,
                  render: (record) => {
                    return record.phone && record.phone.length < 19 ? (
                      <div
                        className="flex w-full"
                        onClick={() => {
                          setSelectedUser(record)
                          setIsOpenDetailModal(true)
                        }}
                      >
                        {record.phone}
                      </div>
                    ) : (
                      <div
                        onClick={() => {
                          setSelectedUser(record)
                          setIsOpenDetailModal(true)
                        }}
                      >{`${record.phone?.slice(0, 19)}..`}</div>
                    )
                  },
                },
                {
                  accessor: "age",
                  title: "Yaş",
                  sortable: true,
                  render: (record) => {
                    return (
                      <div
                        onClick={() => {
                          setSelectedUser(record)
                          setIsOpenDetailModal(true)
                        }}
                      >
                        {record.age}
                      </div>
                    )
                  },
                },
                {
                  accessor: "job",
                  title: "Meslek",
                  sortable: true,
                  render: (record) => {
                    return (
                      <div
                        className="flex w-full"
                        onClick={() => {
                          setSelectedUser(record)
                          setIsOpenDetailModal(true)
                        }}
                      >
                        {record.job}
                      </div>
                    )
                  },
                },
                {
                  accessor: "category",
                  title: "Kategori",
                  sortable: true,
                  render: ({ category, _id }) => {
                    const matchedCategory = BrandSectorsData.find((item) => item.value === category)

                    return (
                      <div className="flex flex-row items-center gap-2">
                        {matchedCategory ? (
                          <>
                            <matchedCategory.image className="h-5 w-5 text-blue-500" />
                            <span>{matchedCategory.name}</span>
                          </>
                        ) : (
                          <span>Kategori Yok</span>
                        )}
                        <FontAwesomeIcon
                          icon={faEdit}
                          style={{ color: "#005eff", cursor: "pointer" }}
                          title="Edit Category"
                          onClick={() => setIsEditCategoryModalOpen({ modal: true, _id })}
                        />
                      </div>
                    )
                  },
                },
                {
                  accessor: "sub_category",
                  title: "Alt Kategori",
                  sortable: true,
                  render: ({ sub_category, _id }) => (
                    <div className="flex items-center gap-2">
                      <span>
                        {sub_category && sub_category.length > 0
                          ? `${sub_category.slice(0, 2).join(", ")}${
                              sub_category.length > 2 ? "..." : ""
                            }`
                          : "Alt Kategori Yok"}
                      </span>
                      <FontAwesomeIcon
                        icon={faEdit}
                        style={{ color: "#005eff", cursor: "pointer" }}
                        title="Edit Sub Category"
                        onClick={() => setIsEditSubCategoryModalOpen({ modal: true, _id })}
                      />
                    </div>
                  ),
                },
                {
                  accessor: "insta.full_name",
                  title: "Insta Tam Adı",
                  sortable: true,
                  render: (record) => {
                    return (
                      <div
                        onClick={() => {
                          setSelectedUser(record)
                          setIsOpenDetailModal(true)
                        }}
                      >
                        {record.insta.full_name}
                      </div>
                    )
                  },
                },
                {
                  accessor: "insta.username",
                  title: "Insta Kullanıcı Adı",
                  sortable: true,
                  render: ({ insta }: any) => (
                    <a
                      href={`https://www.instagram.com/${insta?.username}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="text-center items-center">
                        <span>{insta.username}</span>
                      </div>
                    </a>
                  ),
                },
                {
                  accessor: "tiktok.username",
                  title: "TikTok Kullanıcı Adı",
                  sortable: true,
                  render: ({ tiktok }: any) => (
                    <a
                      href={`https://www.tiktok.com/@${tiktok?.username}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="text-center items-center">
                        <span>{tiktok?.username}</span>
                      </div>
                    </a>
                  ),
                },
                {
                  accessor: "gender",
                  title: "Cinsiyet",
                  sortable: true,
                  render: (record) => (
                    <div
                      className="text-center items-center"
                      onClick={() => {
                        setSelectedUser(record)
                        setIsOpenDetailModal(true)
                      }}
                    >
                      {record.gender === "male" ? (
                        <FontAwesomeIcon icon={faMars} style={{ color: "#005eff" }} />
                      ) : record.gender === "female" ? (
                        <FontAwesomeIcon icon={faVenus} style={{ color: "#ff00dd" }} />
                      ) : null}
                    </div>
                  ),
                },
                {
                  accessor: "country",
                  title: "Ülke",
                  sortable: true,
                  render: (record) => {
                    return (
                      <div
                        onClick={() => {
                          setSelectedUser(record)
                          setIsOpenDetailModal(true)
                        }}
                      >
                        {record.country}
                      </div>
                    )
                  },
                },
                {
                  accessor: "keywordCount",
                  title: "Sıklık",
                  sortable: true,
                  render: (record) => {
                    return (
                      <div
                        onClick={() => {
                          setSelectedUser(record)
                          setIsOpenDetailModal(true)
                        }}
                      >
                        {record.keywordCount}
                      </div>
                    )
                  },
                },
                {
                  accessor: "insta.followers",
                  title: "Insta Takipçi",
                  sortable: true,
                  render: (record) => {
                    return (
                      <div
                        onClick={() => {
                          setSelectedUser(record)
                          setIsOpenDetailModal(true)
                        }}
                      >
                        {numFixer(record.insta.followers)}
                      </div>
                    )
                  },
                },
                {
                  accessor: "insta.following",
                  title: "Insta Takip",
                  sortable: true,
                  render: (record) => {
                    return (
                      <div
                        onClick={() => {
                          setSelectedUser(record)
                          setIsOpenDetailModal(true)
                        }}
                      >
                        {numFixer(record.insta.following)}
                      </div>
                    )
                  },
                },
                {
                  accessor: "insta.average_like",
                  title: "Insta Ortalama Beğeni",
                  sortable: true,
                  render: (record) => {
                    return (
                      <div
                        onClick={() => {
                          setSelectedUser(record)
                          setIsOpenDetailModal(true)
                        }}
                      >
                        {numFixer(record.insta.average_like)}
                      </div>
                    )
                  },
                },
                {
                  accessor: "insta.average_reel_view",
                  title: "Insta Ortalama Reel İzlenme",
                  sortable: true,
                  render: (record) => {
                    return (
                      <div
                        onClick={() => {
                          setSelectedUser(record)
                          setIsOpenDetailModal(true)
                        }}
                      >
                        {numFixer(record.insta?.average_reel_view)}
                      </div>
                    )
                  },
                },
                {
                  accessor: "insta.face_count",
                  title: "Insta Yüz Sayısı",
                  sortable: true,
                  render: (record) => {
                    return (
                      <div
                        onClick={() => {
                          setSelectedUser(record)
                          setIsOpenDetailModal(true)
                        }}
                      >
                        {numFixer(record.insta?.face_count)}
                      </div>
                    )
                  },
                },
                {
                  accessor: "insta.engagement_rate",
                  title: "Insta Etkileşim Oranı",
                  sortable: true,
                  render: (record) => {
                    return (
                      <div
                        onClick={() => {
                          setSelectedUser(record)
                          setIsOpenDetailModal(true)
                        }}
                      >
                        {numFixer(record.insta.engagement_rate)}
                      </div>
                    )
                  },
                },
                {
                  accessor: "insta.post_number",
                  title: "Insta Gönderi Sayısı",
                  sortable: true,
                  render: (record) => {
                    return (
                      <div
                        onClick={() => {
                          setSelectedUser(record)
                          setIsOpenDetailModal(true)
                        }}
                      >
                        {record.insta.post_number}
                      </div>
                    )
                  },
                },
                {
                  accessor: "tiktok.followers",
                  title: "TikTok Takipçi",
                  sortable: true,
                  render: (record) => {
                    return (
                      <div
                        onClick={() => {
                          setSelectedUser(record)
                          setIsOpenDetailModal(true)
                        }}
                      >
                        {numFixer(record.tiktok?.followers)}
                      </div>
                    )
                  },
                },
                {
                  accessor: "tiktok.following",
                  title: "TikTok Takip",
                  sortable: true,
                  render: (record) => {
                    return (
                      <div
                        onClick={() => {
                          setSelectedUser(record)
                          setIsOpenDetailModal(true)
                        }}
                      >
                        {numFixer(record.tiktok?.following)}
                      </div>
                    )
                  },
                },
                {
                  accessor: "tiktok.tiktok_average_like",
                  title: "TikTok Ortalama Beğeni",
                  sortable: true,
                  render: (record) => {
                    return (
                      <div
                        onClick={() => {
                          setSelectedUser(record)
                          setIsOpenDetailModal(true)
                        }}
                      >
                        {numFixer(record.tiktok?.tiktok_average_like)}
                      </div>
                    )
                  },
                },
                {
                  accessor: "tiktok.tiktok_engagement_rate",
                  title: "TikTok Etkileşim Oranı",
                  sortable: true,
                  render: (record) => {
                    return (
                      <div
                        onClick={() => {
                          setSelectedUser(record)
                          setIsOpenDetailModal(true)
                        }}
                      >
                        {numFixer(record.tiktok?.tiktok_engagement_rate)}
                      </div>
                    )
                  },
                },
                {
                  accessor: "tiktok.hearts",
                  title: "TikTok Kalp Sayısı",
                  sortable: true,
                  render: (record) => {
                    return (
                      <div
                        onClick={() => {
                          setSelectedUser(record)
                          setIsOpenDetailModal(true)
                        }}
                      >
                        {numFixer(record.tiktok?.hearts)}
                      </div>
                    )
                  },
                },
              ]}
              totalRecords={totalPages * pageSize}
              recordsPerPage={pageSize}
              page={page}
              onPageChange={(p) => setPage(p)}
              recordsPerPageOptions={PAGE_SIZES}
              onRecordsPerPageChange={setPageSize}
              sortStatus={sortStatus}
              onSortStatusChange={(e) => handleSorting(e.columnAccessor)}
              minHeight={200}
              paginationText={({ from, to, totalRecords }) =>
                `Showing  ${from} to ${to} of ${totalRecords} entries`
              }
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default GetAllUsers
